// WishlistContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const WishlistContext = createContext();

export const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState(
    JSON.parse(localStorage.getItem('wishlist')) || []
  );

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails && userDetails.email) {
      fetchWishlistFromServer(userDetails.email);
    }
  }, []);

  const fetchWishlistFromServer = async (email) => {
    try {
      const response = await axios.get(`https://us-central1-akshayalands-6b7e9.cloudfunctions.net/api/api/wishlist?email=${email}`);
      if (response.status === 200) {
        const serverWishlist = response.data || [];
        localStorage.setItem('wishlist', JSON.stringify(serverWishlist));
        setWishlist(serverWishlist);
      }
    } catch (error) {
      console.error('Error fetching wishlist:', error);
    }
  };

  const addToWishlist = (property) => {
    setWishlist((prev) => {
      const updatedWishlist = [...prev, property];
      localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
      return updatedWishlist;
    });
  };

  const removeFromWishlist = (propertyId) => {
    setWishlist((prev) => {
      const updatedWishlist = prev.filter(property => property._id !== propertyId);
      localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
      return updatedWishlist;
    });
  };

  return (
    <WishlistContext.Provider value={{ wishlist, addToWishlist, removeFromWishlist, setWishlist }}>
      {children}
    </WishlistContext.Provider>
  );
};

export const useWishlist = () => {
  const context = useContext(WishlistContext);
  if (!context) {
    throw new Error('useWishlist must be used within a WishlistProvider');
  }
  return context;
};
