import React from "react";
import { useParams } from "react-router-dom";
import Banner from "./HomePage/Banner";
// import Listings from './Buy/New_List_Buy';
// import FeaturedCollections from './Buy/Featured_Collection_Buy';
import WhatWeHave from "./HomePage/Whatwehave";
import Facilities from "./HomePage/Facilities";
import WhyChooseUs from "./HomePage/Why_Choose";
import Newest_Listing from "./Newest_Listing";
import FeaturedCollections from "./FeaturedCollections";
const CommonPage = () => {
  const { types } = useParams(); // Dynamic route parameter (e.g., Buy, Rent, Lease)
  window.scrollTo({top:0, behavior: 'smooth'})
  return (
    <div style={{backgroundColor:'#e7e1d4'}}>
      {/* <h1>{types.charAt(0).toUpperCase() + types.slice(1)} Properties</h1> */}
      <Banner />
      {/* Pass the dynamic 'types' to Listings and FeaturedCollections */}
      {/* <Listings propertyType={types} />
      <FeaturedCollections propertyType={types} /> */}
      <Newest_Listing />
      <FeaturedCollections />
      <WhatWeHave />
      <Facilities />
      <WhyChooseUs />
    </div>
  );
};
export default CommonPage;
