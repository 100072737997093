import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthProvider';
const EditProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  background-color: ${({ theme }) => theme.colors.light};
  padding: 28px;
`;
const Form = styled.form`
  background-color: ${({ theme }) => theme.colors.extralight};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const InputField = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;
const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;
const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.medium};
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box;
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }
  &[disabled] {
    background-color: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
  }
`;
const UpdateButton = styled.button`
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.light};
  border: none;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;
const EditProfile = () => {
  const { userDetails } = useAuth();
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (userDetails) {
      setProfile({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        phoneNumber: userDetails.phoneNumber || '',
        address: userDetails.address || '',
      });
    }
  }, [userDetails]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting profile data:', profile); // Log the profile data
    try {
      const response = await axios.put('http://localhost:3007/api/update-profile', profile, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // alert(response.data.message); // Show success message
      // Update localStorage with new profile data
      localStorage.setItem('userDetails', JSON.stringify(profile));
      navigate('/profile'); // Redirect to profile page after update
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    }
  };
  return (
    <EditProfileContainer>
      <h2>Edit Profile</h2>
      <Form onSubmit={handleSubmit}>
        <InputField>
          <Label>First Name</Label>
          <Input
            type="text"
            name="firstName"
            value={profile.firstName}
            onChange={handleInputChange}
            required
          />
        </InputField>
        <InputField>
          <Label>Last Name</Label>
          <Input
            type="text"
            name="lastName"
            value={profile.lastName}
            onChange={handleInputChange}
            required
          />
        </InputField>
        <InputField>
          <Label>Email (Non-editable)</Label>
          <Input
            type="email"
            name="email"
            value={profile.email}
            disabled
          />
        </InputField>
        <InputField>
          <Label>Phone Number</Label>
          <Input
            type="text"
            name="phoneNumber"
            value={profile.phoneNumber}
            onChange={handleInputChange}
            required
          />
        </InputField>
        <InputField>
          <Label>Address</Label>
          <Input
            type="text"
            name="address"
            value={profile.address}
            onChange={handleInputChange}
            required
          />
        </InputField>
        <UpdateButton type="submit">Update Profile</UpdateButton>
      </Form>
    </EditProfileContainer>
  );
};
export default EditProfile;