import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
const Popup = ({ message, onClose, onLogin}) => {
  return (
    <Overlay>
      <PopupBox>
        <Message>{message}</Message>
        <ButtonsContainer>
          <Button onClick={onClose}>Close</Button>
          <Button onClick={onLogin}>Login</Button>
        </ButtonsContainer>
      </PopupBox>
    </Overlay>
  );
};
export default Popup;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); // Dark transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Ensure it's above everything else
`;
const PopupBox = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 300px;
`;
const Message = styled.p`
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 10px; // Add space between buttons
`;
const Button = styled.button`
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #0056B3;
  }
`;