import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors.light}; /* Background color for the full screen */
    font-family: Arial, sans-serif;
  }
`;
const Container = styled.div`
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.white}; /* Container background color */
  border-radius: 8px;
  // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;
const Title = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.colors.medium};
  font-size: 2.5em;
  font-family: 'lora';
  margin-bottom: 20px;
`;
const Section = styled.section`
  margin: 30px 0;
`;
const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.medium};
  margin-bottom: 10px;
  font-size: 1.8em;
  font-family: 'lora';
`;
const Paragraph = styled.p`
  color: #000000;
  line-height: 1.8;
  font-size: 1.1em;
  font-family: 'lora';
  margin-bottom: 15px;
`;
const ServiceList = styled.ul`
  list-style: none;
  padding: 0;
  li {
    background: ${({ theme }) => theme.colors.white}; /* Assuming you want white background for list items */
    padding: 15px;
    margin: 10px 0;
    font-family: 'lora';
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    color: ${({ theme }) => theme.colors.dark}; /* Text color set to dark theme */
    transition: background 0.3s, color 0.3s;
    &:hover {
      background: ${({ theme }) => theme.colors.medium}; /* Change background on hover */
      color: ${({ theme }) => theme.colors.light}; /* Change text color on hover */
    }
  }
`;
const AboutUs = () => {
  return (
    <>
      <GlobalStyle />
      <Container>
        <Title>About Us</Title>
        <Section>
          <Paragraph>
            Akshaya Land is India’s largest online B2B marketplace, connecting buyers with suppliers. We provide a one-stop platform for our customers to buy and sell land online. Our experience in handling land requirements extends to setting up data centers and landing stations.
          </Paragraph>
          <Paragraph>
            The company enjoys warm relations with manufacturers and continues to win accolades for its sales, riding on the success of the Indian mobile revolution. In the years to come, Akshaya Land aims to set new benchmarks in innovative world-class real estate and aspires to emerge as India's largest platform for land buying and selling. This e-commerce platform is poised to revolutionize consumers' shopping experiences as they seek new and better avenues.
          </Paragraph>
        </Section>
        <Section>
          <Subtitle>Who We Are?</Subtitle>
          <Paragraph>
            We gather talent and inspiration from creatives around the world. We believe in the power of good design and community.
          </Paragraph>
        </Section>
        <Section>
          <Subtitle>Our Services</Subtitle>
          <ServiceList>
            <li>Buying & Selling</li>
            <li>Land Portfolio Optimization</li>
            <li>Structured Disposition</li>
          </ServiceList>
        </Section>
      </Container>
    </>
  );
};
export default AboutUs;