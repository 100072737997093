import React, { useEffect, useState } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../../firebaseConfig'; // Ensure the path to your Firebase configuration is correct

const PhoneSignUp = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [otp, setOtp] = useState('');

  useEffect(() => {
    // Create reCAPTCHA verifier instance
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'normal',
        'callback': (response) => {
          console.log('reCAPTCHA solved');
        },
        'expired-callback': () => {
          console.log('reCAPTCHA expired');
        }
      });
      

    // Clean up the verifier on component unmount
    return () => {
      window.recaptchaVerifier.clear();
    };
  }, []);

 

// Function to send the OTP
const handleSendOTP = () => {
    const phoneNumber = '+919894287524';  // User's phone number
    const appVerifier = window.recaptchaVerifier; // reCAPTCHA verifier

    firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((result) => {
            console.log('OTP sent successfully');
            confirmationResult = result;  // Save the confirmation result
        })
        .catch((error) => {
            console.error('Error sending OTP:', error);
        });
};

// Function to verify the OTP
const handleVerifyOTP = () => {
    const otpCode = prompt('Enter the OTP code');  // Or use an input field to get OTP
    confirmationResult.confirm(otpCode)
        .then((result) => {
            const user = result.user;
            console.log('OTP verified successfully, user signed in:', user);
        })
        .catch((error) => {
            console.error('Error verifying OTP:', error);
        });
};

  return (
    <div>
      <h1>Phone Sign-Up</h1>
      {!isOTPSent ? (
        <form onSubmit={handleSendOTP}>
          <input 
            type="tel" 
            value={phoneNumber} 
            onChange={(e) => setPhoneNumber(e.target.value)} 
            placeholder="Enter your phone number (e.g., +919876543210)" 
            required 
          />
          <button type="submit">Send Verification Code</button>
          <div id="recaptcha-container"></div> {/* This is necessary for reCAPTCHA */}
        </form>
      ) : (
        <form onSubmit={handleVerifyOTP}>
          <input 
            type="text" 
            value={otp} 
            onChange={(e) => setOtp(e.target.value)} 
            placeholder="Enter the OTP sent to your phone" 
            required 
          />
          <button type="submit">Verify OTP</button>
        </form>
      )}
    </div>
  );
};

export default PhoneSignUp;
