import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import Loader from '../Loader';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.light};
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.light};
  border-bottom: 1px solid #E0E0E0;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 15px;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const Title = styled.h2`
  margin-right: 20px;
`;
export const Dropdown = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;
export const ListingSection = styled.div`
  flex: 1;
  padding: 20px 40px;
  background-color: ${({ theme }) => theme.colors.light};
  @media (max-width: 1024px) {
    padding: 20px;
  }
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  width: 100%;
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
  }
`;
export const Card = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;
export const Image = styled.img`
  width: 100%;
  max-width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
`;
export const Description = styled.p`
  font-size: 14px;
  font-family: 'lora';
  color: #666;
  margin: 8px 0;
  text-align: center;
  strong {
    color: #333;
  }
`;
export const ViewButton = styled.button`
  background-color: ${({ theme }) => theme.colors.medium};
  color: white;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;
const CustomToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    color: #fff;
    font-family: 'lora';
    font-size: 16px;
  }
  .Toastify__toast--info {
    background-color: #17A2B8;
  }
  .Toastify__toast--success {
    background-color: green;
  }
  .Toastify__toast--warning {
    background-color: #FFC107;
  }
  .Toastify__toast--error {
    background-color: #DC3545;
  }
  .Toastify__close-button {
    color: black;
  }
  .Toastify__progress-bar {
    background: #007BFF;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  max-width: 400px; /* Set a maximum width for the input */
  padding: 10px 15px; /* Padding for a better touch area */
  margin-right: 20px; /* Spacing between the input and dropdown */
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  font-family: 'lora';
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: border-color 0.3s, box-shadow 0.3s; /* Transition for hover effects */

  &:focus {
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Shadow on focus */
    outline: none; /* Remove outline */
  }

  &::placeholder {
    color: #aaa; /* Placeholder color */
  }
`;

const ApproveProperty = ({ setSelectedView }) => {
  const { title } = useParams();
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [types, setTypes] = useState('all');
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const propertyList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/approvedAdmin`);
      const filteredData = response.data.map(property => {
        const { status, ...rest } = property; // Destructure to exclude status
        return rest; // Return the remaining fields
      });
      setList(filteredData);
      setFilteredList(filteredData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    propertyList(); // Fetch properties on component load
  }, []);

  useEffect(() => {
    const filteredByType = types === 'all' ? list : list.filter(item => item.types.toLowerCase() === types.toLowerCase());
    const filteredByCode = searchQuery ? filteredByType.filter(item => item.code.toLowerCase().includes(searchQuery.toLowerCase())) : filteredByType;
    setFilteredList(filteredByCode);
  }, [types, list, searchQuery]);

  const handleReject = async (propertyId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reject-property`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ propertyId }),
      });
      if (!response.ok) {
        throw new Error('Failed to reject property');
      }
      const result = await response.json();
      toast.success(result.message);
      propertyList(); // Refresh the property list
    } catch (error) {
      console.error('Error rejecting property:', error);
      toast.error('Error rejecting property. Please try again.');
    }
  };

  if (loading) return <Loader />;

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Title>Approved Properties</Title>
        </TitleContainer>
        <SearchInput
          type="text"
          placeholder="Search by Code ID"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Dropdown value={types} onChange={(e) => setTypes(e.target.value)}>
          <option value="all">All Types</option>
          <option value="sell">Sell</option>
          <option value="rent">Rent</option>
          <option value="lease">Lease</option>
        </Dropdown>
      </Header>
      <ListingSection>
        <Grid>
          {filteredList.length > 0 ? (
            filteredList.map((item, index) => (
              <Card key={index}>
                <Image
                  src={item.files && item.files[0] ? item.files[0] : 'https://via.placeholder.com/150'}
                  alt={item.propertyName}
                />
                <Description><strong>Code ID:</strong> {item.code}</Description>
                <Description><strong>Property Name:</strong> {item.propertyName}</Description>
                <Description><strong>Property Type:</strong> {item.propertyType}</Description>
                <Description><strong>Types:</strong> {item.types}</Description>
                <Description><strong>Budget:</strong> {item.budget} {item.budgetMethod}</Description>
                <Description><strong>Contact Owner Name:</strong> {item.contactOwnerName}</Description>
                <Description><strong>Contact Phone Number:</strong> {item.contactOwnerPhone}</Description>
                <ViewButton onClick={() => handleReject(item._id)}>Reject</ViewButton>
                <ViewButton onClick={() => navigate(`/individualadmin/${item._id}`)}>View Details</ViewButton>
              </Card>
            ))
          ) : (
            <p>No properties found for the selected type or search query.</p>
          )}
        </Grid>
        <CustomToastContainer />
      </ListingSection>
    </Container>
  );
};

export default ApproveProperty;
