import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled, { css } from "styled-components";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import useMediaQuery from "../useMediaQuery";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FaTimes } from "react-icons/fa";
import Loader from "./Loader";

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.light};
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const BudgetSection = styled.div`
  margin-top: 20px;
  padding: 20px;
`;

const BudgetFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Dropdown = styled.select`
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-left: 10px;
`;

const SliderWrapper = styled.div`
  margin-top: 15px;
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const FiltersSection = styled.div`
  width: 300px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: auto;
  overflow-y: auto;
  ${({ isOpen }) =>
    isOpen
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
  @media (min-width: 1024px) {
    width: 300px;
    position: sticky;
    top: 0;
    height: 100vh;
    display: block;
  }
`;

const FiltersContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const FilterContainer = styled.div`
  background-color: #fff;
  // padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const ClearText = styled.span`
  color: #954535;
  cursor: pointer;
  &:hover {
    color: #2a2e27;
    text-decoration: none;
  }
`;

export const FilterItem = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 20px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Button = styled.button`
  background-color: #c1887c;
  color: #e7e1d4;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1 1 auto;
  max-width: 120px;
  &:hover {
    background-color: #c1887c;
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Checkbox = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  input {
    transform: scale(1.2);
  }
`;

const ListingSection = styled.div`
  flex: 1;
  margin-top: 20px;
  @media (min-width: 768px) {
    margin-top: 0;
    padding: 40px;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 20px;
  row-gap: 50px;

  /* For desktops and laptops (4 columns) */
  grid-template-columns: repeat(4, 1fr);

  /* For tablets (3 columns) */
  @media (max-width: 1240px) {
    grid-template-columns: repeat(3, 1fr);
  }

  /* For tablets (2 columns) */
  @media (max-width: 1061px) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* For mobile phones (2 columns) */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom:40px;
  }
`;

export const FilterIcon = styled.div`
  font-size: 24px;
  cursor: pointer;
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  h2 {
    margin: 0;
  }
  @media (max-width: 1023px) {
    h2 {
      margin-right: 10px;
    }
  }
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* To ensure consistent spacing */
  height: 100%; /* Ensure the card takes full height of grid */
`;

export const Image = styled.img`
  width: 100%;
  height: 180px; /* Set a fixed height for images */
  object-fit: cover; /* This will maintain the aspect ratio and fill the container */
  border-radius: 10px;
`;

export const Description = styled.p`
  font-family: 'lora';
  margin: 10px 0;
  flex-grow: 1; /* Allows text to grow and maintain spacing */
`;

export const ViewButton = styled.button`
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.light};
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #b87759;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #a4694f;
  }
`;

export const FilterOption = styled.div`
  margin-bottom: 10px;
  background-color: #b2786b;
  border: none;
  color: white;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  gap: 10px;
  display: flex;
`;

const FilterOptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const FilterButton = styled.button`
  background-color: #b2786b;
  border: none;
  color: white;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f58c72;
  }
`;

const CardContent = styled.div`
  text-align: left; // Aligns all the text to the left
`;

const CloseIcon = styled(FaTimes)`
  cursor: pointer;
  position: absolute; /* Position it at the top-right corner */
  top: 10px; /* Adjust based on your design */
  right: 10px; /* Adjust based on your design */
  font-size: 24px; /* Adjust icon size */
  color: #333; /* Adjust icon color */
`;

const StyledInput = styled.input`
  width: 80px; /* Specify the width of the input */
  height: 20px; /* Specify the height of the input */
  border: 2px solid #c1887c; /* Border color */
  border-radius: 8px; /* Rounded corners */
  padding: 8px; /* Inner padding for better spacing */
  font-size: 16px; /* Font size */
  outline: none; /* Remove outline on focus */
  transition: border-color 0.3s ease; /* Smooth transition for border color */
  /* Change border color on focus */
  &:focus {
    border-color: #9c7b73; /* Change this to your desired color on focus */
  }
`;

const SpaceSpan = styled.span`
  margin: 0 8px; /* Adjust the values as needed */
`;

const ErrorText = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

const NoPropertiesContainer = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Align to the top */
  text-align: center;
  margin-top: 20px; /* Optional: Adjust the top margin for spacing */
  img {
    max-width: 100%;
    height: auto;
    max-height: 400px; /* Adjust this as needed */
  }
`;

//-----------------
const FilterWrapper = styled.div`
  margin-bottom: 20px;
`;

const FilterSubWrapper = styled.div`
  display: ${({ hide }) => (hide ? "none" : "block")};
  // margin-bottom: 10px;
  background-color: #fff;
  // padding: 20px;
  flex-direction: column;
  align-items: flex-start;
`;

const FilterLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const Clear = styled.span`
  color: #954535;
  cursor: pointer;
  &:hover {
    color: #2a2e27;
    text-decoration: none;
  }
`;

const FilterOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const PriceInput = styled.input`
  width: calc(50% - 10px);
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ToText = styled.span`
  margin-right: 10px;
`;

const ApplyButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const PropertyListing = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [appliedBudgetFilter, setAppliedBudgetFilter] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const types = queryParams.get("types");
  const propertyName = queryParams.get("propertyName");
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);

  const handleClear = (filterType) => {
    switch (filterType) {
      case "propertyType":
        setSelectedPropertyTypes([]);
        break;
      case "rooms":
        setSelectedRooms([]);
        break;
      case "location":
        setSelectedLocations([]);
        break;
      case "budget":
        setMinPrice("");
        setMaxPrice("");
        setIsFilterApplied(false);
        break;
      default:
        break;
    }
  };

  const handleMinChange = (e) => {
    setMinPrice(e.target.value);
    setIsFilterApplied(false);
  };

  const handleMaxChange = (e) => {
    setMaxPrice(e.target.value);
    setIsFilterApplied(false);
  };

  const applyBudgetFilter = () => {
    const min = parseInt(minPrice, 10);
    const max = parseInt(maxPrice, 10);

    // Clear previous error state
    setErrorMessage("");
    setHasError(false);

    // Logic for clearing the filter
    if (isFilterApplied) {
      // Resetting state to default
      setMinPrice(""); // Reset min price
      setMaxPrice(""); // Reset max price
      setAppliedBudgetFilter(""); // Clear the applied budget filter text
      setIsFilterApplied(false); // Update filter applied state

      // Call function to fetch all properties
      fetchProperties(true); // Fetch all properties, pass true to reset filters
      return; // Exit the function
    }

    // Logic for applying the filter
    if (!minPrice && !maxPrice) {
      setErrorMessage("Please enter a value in at least one field."); // Error for empty fields
      setHasError(true);
      return;
    }

    if (min && max && min > max) {
      setErrorMessage("Min price cannot be greater than Max price!"); // Error for invalid min > max
      setHasError(true);
      return;
    }

    let budgetText = "";

    if (minPrice && !maxPrice) {
      budgetText = `₹${minPrice} +`; // User entered only the min price
    } else if (!minPrice && maxPrice) {
      budgetText = `Up to ₹${maxPrice}`; // User entered only the max price
    } else if (minPrice && maxPrice) {
      budgetText = `₹${minPrice} - ₹${maxPrice}`; // User entered both min and max prices
    }

    if (budgetText) {
      setAppliedBudgetFilter(budgetText); // Apply the formatted budget text
      fetchProperties(); // Fetch filtered properties
      setIsFilterApplied(true); // Change button text to 'Clear'
    }
  };

  const shouldHideRoomsFilter =
    propertyName === "Land" || propertyName === "Store";

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1024);
  };

  const fetchProperties = async (reset = false) => {
    try {
      // Prepare the query parameters
      const queryParams = new URLSearchParams({
        types: types || "",
        propertyName: propertyName || "",
      }).toString();

      // If reset is true, do not apply any filters; fetch all properties
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getAllSells?${queryParams}`
      );
      const properties = response.data.properties;

      // Extract unique values
      const uniqueTypes = [...new Set(properties.map((p) => p.propertyType))];
      const uniqueRooms = [...new Set(properties.map((p) => p.numberOfRooms))];
      const uniqueLocations = [...new Set(properties.map((p) => p.city))];

      setPropertyTypes(uniqueTypes);
      setRooms(uniqueRooms);
      setLocations(uniqueLocations);

      // If reset is true, set the full property list
      if (reset) {
        setList(properties); // Display all properties without filtering
        return; // Exit the function early
      }

      // Filter properties based on budget range and selected filters
      const filteredProperties = properties.filter((property) => {
        // Parse the budget range values to numbers, or default to null if empty
        const minBudget = minPrice !== "" ? Number(minPrice) : null;
        const maxBudget = maxPrice !== "" ? Number(maxPrice) : null;

        const isWithinBudget =
          (minBudget === null || property.budget >= minBudget) &&
          (maxBudget === null || property.budget <= maxBudget);

        return (
          isWithinBudget &&
          (selectedPropertyTypes.length === 0 ||
            selectedPropertyTypes.includes(property.propertyType)) &&
          (selectedRooms.length === 0 ||
            selectedRooms.includes(property.numberOfRooms)) &&
          (selectedLocations.length === 0 ||
            selectedLocations.includes(property.city)) &&
          (propertyName ? property.propertyName === propertyName : true)
        );
      });

      setList(filteredProperties);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    fetchProperties();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [, selectedPropertyTypes, selectedRooms, selectedLocations]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleViewDetails = (propertyId) => {
    navigate(`/view-property5/${propertyId}`);
  };

  const handleClearFilters = () => {
    setSelectedPropertyTypes([]); // Clear Property Types
    setSelectedRooms([]); // Clear Rooms
    setSelectedLocations([]); // Clear Locations
    setMinPrice(""); // Clear Min Price
    setMaxPrice(""); // Clear Max Price
    setAppliedBudgetFilter(""); // Clear Budget Filter Text
    setIsFilterApplied(false); // Reset Apply/Clear Button in Budget
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handlePropertyTypeClick = (type) => {
    setSelectedPropertyTypes((prev) =>
      prev.includes(type)
        ? prev.filter((property) => property !== type)
        : [...prev, type]
    );
  };

  const handleRoomClick = (room) => {
    setSelectedRooms((prev) =>
      prev.includes(room)
        ? prev.filter((property) => property !== room)
        : [...prev, room]
    );
  };

  const handleLocationClick = (location) => {
    setSelectedLocations((prev) =>
      prev.includes(location)
        ? prev.filter((property) => property !== location)
        : [...prev, location]
    );
  };

  const handleRemoveFilter = (filterType, filterValue) => {
    switch (filterType) {
      case "budget":
        setMinPrice("");
        setMaxPrice("");
        setAppliedBudgetFilter("");
        setIsFilterApplied(false);
        resetPropertyList();
        break;
      case "propertyType":
        setSelectedPropertyTypes((prev) =>
          prev.filter((property) => property !== filterValue)
        );
        break;
      case "room":
        setSelectedRooms((prev) =>
          prev.filter((property) => property !== filterValue)
        );
        break;
      case "location":
        setSelectedLocations((prev) =>
          prev.filter((property) => property !== filterValue)
        );
        break;
      default:
        break;
    }
  };

  const resetPropertyList = () => {
    fetchProperties(true);
  };

  const isFiltersSectionVisible =
    propertyTypes.length > 0 || rooms.length > 0 || locations.length > 0;

  const hasFiltersApplied =
    appliedBudgetFilter ||
    selectedPropertyTypes.length > 0 ||
    selectedRooms.length > 0 ||
    selectedLocations.length > 0;
  return (
    <Container>
      <FiltersSection
        isOpen={isFilterOpen}
        style={{ display: isFiltersSectionVisible ? "block" : "none" }}
      >
        {hasFiltersApplied && (
          <FilterContainer>
            <HeaderWrapper>
              <h3>Applied Filters</h3>
              <ClearText onClick={handleClearFilters}>Clear All</ClearText>
            </HeaderWrapper>

            {appliedBudgetFilter && (
              <FilterOptionWrapper>
                <FilterButton
                  onClick={() =>
                    handleRemoveFilter("budget", appliedBudgetFilter)
                  }
                >
                  {appliedBudgetFilter}{" "}
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ marginLeft: "8px", cursor: "pointer" }}
                  />
                </FilterButton>
              </FilterOptionWrapper>
            )}

            {selectedPropertyTypes.length > 0 && (
              <FilterOptionWrapper>
                {selectedPropertyTypes.map((type, index) => (
                  <FilterButton
                    key={index}
                    onClick={() => handleRemoveFilter("propertyType", type)}
                  >
                    {type}
                    <FontAwesomeIcon
                      icon={faTimes}
                      style={{ marginLeft: "8px", cursor: "pointer" }}
                    />
                  </FilterButton>
                ))}
              </FilterOptionWrapper>
            )}

            {selectedRooms.length > 0 && (
              <FilterOptionWrapper>
                {selectedRooms.map((room, index) => (
                  <FilterButton
                    key={index}
                    onClick={() => handleRemoveFilter("room", room)}
                  >
                    {room}
                    <FontAwesomeIcon
                      icon={faTimes}
                      style={{ marginLeft: "8px", cursor: "pointer" }}
                    />
                  </FilterButton>
                ))}
              </FilterOptionWrapper>
            )}

            {selectedLocations.length > 0 && (
              <FilterOptionWrapper>
                {selectedLocations.map((location, index) => (
                  <FilterButton
                    key={index}
                    onClick={() => handleRemoveFilter("location", location)}
                  >
                    {location}
                    <FontAwesomeIcon
                      icon={faTimes}
                      style={{ marginLeft: "8px", cursor: "pointer" }}
                    />
                  </FilterButton>
                ))}
              </FilterOptionWrapper>
            )}
          </FilterContainer>
        )}

        {/* Render the filter categories */}
        <FilterSubWrapper>
          <FilterLabel>
            <h3>Property Type</h3>
            <Clear onClick={() => handleClear("propertyType")}>Clear</Clear>
          </FilterLabel>
          <FilterOptions>
            {propertyTypes.length > 0
              ? propertyTypes.map((type, index) => (
                  <FilterOption
                    key={index}
                    onClick={() => handlePropertyTypeClick(type)}
                    selected={selectedPropertyTypes.includes(type)}
                  >
                    {type}
                  </FilterOption>
                ))
              : null}{" "}
            {/* Don't show any options */}
          </FilterOptions>
        </FilterSubWrapper>

        {!shouldHideRoomsFilter && (
          <FilterSubWrapper>
            <FilterLabel>
              <h3>Rooms</h3>
              <Clear onClick={() => handleClear("rooms")}>Clear</Clear>
            </FilterLabel>

            <FilterOptions>
              {rooms.length > 0
                ? rooms.map((room, index) => (
                    <FilterOption
                      key={index}
                      onClick={() => handleRoomClick(room)}
                      selected={selectedRooms.includes(room)}
                    >
                      {room}
                    </FilterOption>
                  ))
                : null}{" "}
              {/* Don't show any options */}
            </FilterOptions>
          </FilterSubWrapper>
        )}

        <FilterSubWrapper>
          <FilterLabel>
            <h3>Location</h3>
            <Clear onClick={() => handleClear("location")}>Clear</Clear>
          </FilterLabel>

          <FilterOptions>
            {locations.length > 0
              ? locations.map((location, index) => (
                  <FilterOption
                    key={index}
                    onClick={() => handleLocationClick(location)}
                    selected={selectedLocations.includes(location)}
                  >
                    {location}
                  </FilterOption>
                ))
              : null}{" "}
            {/* Don't show any options */}
          </FilterOptions>
        </FilterSubWrapper>

        <FilterSubWrapper>
          <FilterLabel>
            <h3>Budget</h3>
            <Clear onClick={applyBudgetFilter}>
              {isFilterApplied ? "Clear" : "Apply"}
            </Clear>
          </FilterLabel>

          <PriceWrapper>
            <PriceInput
              type="tel"
              placeholder="Min Price"
              value={minPrice}
              onChange={handleMinChange}
              style={{ borderColor: hasError ? "red" : "#ccc" }}
            />
            <ToText>To</ToText>
            <PriceInput
              type="tel"
              placeholder="Max Price"
              value={maxPrice}
              onChange={handleMaxChange}
              style={{ borderColor: hasError ? "red" : "#ccc" }}
            />
          </PriceWrapper>

          {errorMessage && (
            <ErrorText style={{ color: "red", marginTop: "8px" }}>
              {errorMessage}
            </ErrorText>
          )}
        </FilterSubWrapper>
      </FiltersSection>

      <ListingSection>
        <FilterHeader>
          <h2>{`${types === "Sell" ? "Buy" : types} - ${propertyName}`}</h2>
          {isMobile && (
            <FilterIcon onClick={toggleFilter}>
              <i className="fas fa-filter"></i>
            </FilterIcon>
          )}
        </FilterHeader>
        {loading ? (
          <div>
            <Loader />
          </div>
        ) : list.length === 0 ? (
          <NoPropertiesContainer>
            {" "}
            <img
              src="https://cdn.shopify.com/s/files/1/0851/4607/5431/files/No_Properties_Found...png?v=1728384561"
              alt="No properties found"
            />{" "}
          </NoPropertiesContainer>
        ) : (
          <Grid>
            {list.map((item, index) => (
              <Card key={index}>
                <Image
                  src={item.files || "https://via.placeholder.com/150"}
                  alt={item.propertyName}
                />
                <CardContent>
                <Description>
                    <strong>Code ID:</strong> {item.code}
                  </Description>
                  <Description>
                    <strong>Property Type:</strong> {item.propertyType}
                  </Description>
                  <Description>
                    <strong>Budget:</strong> {item.budget}
                    {` / ${item.budgetMethod}`}
                  </Description>
                  {item.numberOfSqFeet && (
                    <Description>
                      <strong>No. of sq.ft:</strong> {item.numberOfSqFeet}
                    </Description>
                  )}
                  {item.propertyName !== "Land" && item.numberOfRooms && (
                    <Description>
                      <strong>No. of Rooms:</strong> {item.numberOfRooms}
                    </Description>
                  )}
                  <Description>
                    <strong>Location:</strong> {item.city}
                  </Description>
                </CardContent>
                <ViewButton onClick={() => handleViewDetails(item._id)}>
                  View Details
                </ViewButton>
              </Card>
            ))}
          </Grid>
        )}
      </ListingSection>
    </Container>
  );
};

export default PropertyListing;
