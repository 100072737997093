import React, { useState } from "react";
import { storage } from "../firebaseConfig"; // Import your Firebase storage config
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from "axios";

const ImageTest = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageData, setImageData] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setImageData(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    if (!imageData) {
      alert("Please select an image to save.");
      return;
    }

    // Create a storage reference
    const storageRef = ref(storage, `images/${Date.now()}_${imageData.name}`);

    try {
      // Upload the file to Firebase Storage
      await uploadBytes(storageRef, imageData);
      
      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);

      // You can now store the downloadURL in your MongoDB database
      const response = await axios.post("http://localhost:3007/api/saveImage", {
        filename: imageData.name,
        imageUrl: downloadURL,
      });

      alert("Image saved successfully!");
      console.log(response.data);
    } catch (error) {
      console.error("Error saving image:", error);
      alert("Failed to save image.");
    }
  };

  return (
    <div>
      <h1>Upload an Image</h1>
      <input type="file" accept="image/*" onChange={handleImageChange} />
      {selectedImage && (
        <div style={{ marginTop: "20px" }}>
          <h3>Image Preview:</h3>
          <img src={selectedImage} alt="Selected" style={{ width: "300px", height: "auto" }} />
          <button onClick={handleSave}>Save Image</button>
        </div>
      )}
    </div>
  );
};

export default ImageTest;