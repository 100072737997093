// PrivacyPolicy.js
import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  min-height: 100vh; /* Ensure container takes at least full viewport height */
  margin: 0;
  padding: 20px; /* Add padding for better readability */
  background-color: ${({ theme }) => theme.colors.light};
  color: ${({ theme }) => theme.colors.dark};
  line-height: 1.6;
  box-sizing: border-box; /* Ensure padding is included in height calculation */
`;
const Section = styled.section`
  margin-bottom: 20px;
  width: 100%; /* Ensure sections take full width */
  max-width: 800px; /* Optional: limit maximum width for better readability */
`;
const Title = styled.h1`
  font-size: 2rem;
  font-family: 'lora';
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.medium};
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;
const SubTitle = styled.h2`
  font-size: 1.5rem;
  font-family: 'lora';
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.medium};
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
const Paragraph = styled.p`
  font-size: 1rem;
  font-family: 'lora';
  margin-bottom: 10px;
  color:#000000;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;
const Refund = () => {
    return (
        <Container>
            <Title>Refund and Cancellation Policy</Title>
            <Section>
                <SubTitle>Effective Date: [17/10/2024]</SubTitle>
                <Paragraph>
                    This Refund and Cancellation Policy outlines the terms and conditions
                    governing refunds and cancellations for membership services provided by
                    Akshaya Lands, a real estate firm operating in India. By purchasing a
                    membership, you agree to abide by the following policy:
                </Paragraph>
            </Section>
            <Section>
                <SubTitle> 1. No Refunds or Cancellations</SubTitle>
                <Paragraph> Once a membership payment has been successfully made, no refunds or
                    cancellations will be permitted. Membership fees are non-refundable,
                    non-transferable, and non-negotiable under any circumstances, including
                    but not limited to:</Paragraph>
                <Paragraph>i. Change of mind</Paragraph>
                <Paragraph>ii. Dissatisfaction with services  </Paragraph>
                <Paragraph>iii. Personal or financial constraints</Paragraph>
                <Paragraph>iv. Any other reasons not covered by legal obligations.</Paragraph>
            </Section>
            <Section>
                <SubTitle>2. Non-Transferable</SubTitle>
                <Paragraph>The membership is issued exclusively to the purchaser and cannot be
                    transferred to any other individual or entity under any circumstances.
                </Paragraph>

            </Section>
            <Section>
                <SubTitle>3. Commitment to Service                </SubTitle>
                <Paragraph>We are committed to providing high-quality real estate services and
                    membership benefits as outlined in our service agreements. We encourage
                    prospective members to carefully review all terms and service offerings
                    before making any payment.
                </Paragraph>

            </Section>
            <Section>
                <SubTitle>4. Exceptions                </SubTitle>
                <Paragraph>The only exceptions to this policy will be in cases where the law explicitly
                    mandates a refund or cancellation, such as situations involving fraud,
                    misrepresentation, or breach of contract on the part of Akshaya Lands.</Paragraph>
            </Section>
            <Section>
                <SubTitle>5. Disputes</SubTitle>
                <Paragraph>In case of any disputes related to the membership, we encourage members
                    to contact our customer support team to resolve any issues amicably.
                    However, please note that this policy remains binding and overrides any
                    informal discussions unless otherwise required by applicable law.
                </Paragraph>
            </Section>
            <Section>
                <SubTitle>6. Contact Information                </SubTitle>
                <Paragraph>For any queries related to this policy, please contact us at:</Paragraph>
                <Paragraph><strong> Email: akshayalands.tup@gmail.com             </strong></Paragraph>
                <Paragraph><strong> Phone: +91 9843353364, +91 6385324990, +91 9443081990                </strong></Paragraph>
                <Paragraph><strong> Office Address:17/4. Town Extension, 2nd Street,Cotton Market
                    Post,Tirupur- 641604.</strong></Paragraph>
                <Paragraph> Akshaya Lands reserves the right to amend this policy at its discretion.
                    Any amendments will be effective upon publication on our official website or
                    through other communication channels.</Paragraph>
            </Section>

        </Container>
    );
};
export default Refund;






