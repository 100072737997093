import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../AuthProvider';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
//   background-color: ${({ theme }) => theme.colors.dark};
  padding: 15px 30px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 100;
  flex-wrap: wrap;
  padding-right:100px;

  @media (max-width: 768px) {
    padding: 10px 15px;
    flex-direction: column;
    // align-items: stretch;
    background: linear-gradient(135deg, #2E3A59, #3B5066, #434F81, #7C5DB2); 
  }
`;

const DesktopSearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 20px;
  padding: 5px 15px;
  width: 300px;
  max-width: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  flex-grow: 1;
  z-index: 101;

  input {
    border: none;
    background: none;
    padding: 5px;
    font-size: 16px;
    outline: none;
    flex-grow: 1;
    color: ${({ theme }) => theme.colors.dark};
  }

  button {
    border: none;
    background: none;
    font-size: 18px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.dark};
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    width: 90%;
    border-radius: 10px;
    padding: 5px;
  }
`;

const SearchDropdown = styled.div`
  position: absolute;
  top: 110%;
  left: 50%;
  width: 110%; /* Increase the width slightly */
  max-height: 300px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 9999;
  overflow-y: auto;
  border-radius: 8px;
  transform: translateX(-50%); /* Center the dropdown horizontally */

  .dropdown-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .suggestions-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .suggestion-item {
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .suggestion-item:hover {
    background-color: #f0f0f0;
  }

  .suggestion-item.disabled {
    cursor: not-allowed;
    color: #aaa;
    background-color: #f9f9f9;
  }
`;

const HighlightedText = styled.span`
  font-weight: bold;
  color: #007BFF;
`;

const Search = () => {
  const { isAuthenticated, logout } = useAuth();
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const searchRef = useRef(null);

  const fetchSuggestions = async () => {
    if (!query.trim()) return;
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/searchsuggestion`, {
        params: { query },
      });
      
      let fetchedSuggestions = response.data;
  
      // Sort suggestions: propertyName or city starting with the query comes first
      fetchedSuggestions = fetchedSuggestions.sort((a, b) => {
        const queryLower = query.toLowerCase();
        
        // Check if `propertyName` or `city` starts with the query and sort accordingly
        const aPropertyStartsWith = a.propertyName.toLowerCase().startsWith(queryLower);
        const bPropertyStartsWith = b.propertyName.toLowerCase().startsWith(queryLower);
        const aCityStartsWith = a.city?.toLowerCase().startsWith(queryLower) || false;
        const bCityStartsWith = b.city?.toLowerCase().startsWith(queryLower) || false;
  
        // Prioritize matches that start with the query
        if (aPropertyStartsWith || aCityStartsWith) return -1;
        if (bPropertyStartsWith || bCityStartsWith) return 1;
  
        // No preference if neither starts with the query
        return 0;
      });
  
      setSuggestions(fetchedSuggestions);
      setIsDropdownOpen(true);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  useEffect(() => {
    if (query.trim()) {
      fetchSuggestions();
    } else {
      setSuggestions([]);
      setIsDropdownOpen(false);
    }
  }, [query]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSuggestionClick = (suggestion) => {
    const path = `/propertylistings/${encodeURIComponent(suggestion.propertyName || '')}/${encodeURIComponent(suggestion.city || '')}`;
    navigate(path);
    setQuery('');
    setSuggestions([]);
    setIsDropdownOpen(false);
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    const path = `/propertylistings/${encodeURIComponent(query)}`;
    navigate(path);
    setQuery('');
    setSuggestions([]);
    setIsDropdownOpen(false);
  };

  const highlightQuery = (text, query) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <HighlightedText key={index}>{part}</HighlightedText>
      ) : (
        part
      )
    );
  };

  const renderSuggestionItem = (item) => {
    const highlightedPropertyName = highlightQuery(item.propertyName || '', query);
    const highlightedCity = highlightQuery(item.city || '', query);

    return (
      <div
        className="suggestion-item"
        onClick={() => handleSuggestionClick(item)}
      >
        {highlightedPropertyName}
        {item.city && <span>, {highlightedCity}</span>}
      </div>
    );
  };

  return (
    <HeaderContainer>
      <DesktopSearchContainer ref={searchRef}>
       
        <input
          type="text"
          placeholder="Suggestion PropertyName,location"
          value={query}
          onChange={handleInputChange}
          onClick={() => setIsDropdownOpen(suggestions.length > 0)}
        />
        <button>
          <i className="fas fa-search"></i>
        </button>
        {isDropdownOpen && suggestions.length > 0 && (
          <SearchDropdown isOpen={isDropdownOpen}>
            <div className="dropdown-header">
              <button onClick={() => { setQuery(''); setIsDropdownOpen(false); }}>
                <i className="fas fa-times"></i>
              </button>
            </div>
            {suggestions.length > 0 ? (
              suggestions.map((item, index) => (
                <React.Fragment key={index}>
                  {renderSuggestionItem(item)}
                </React.Fragment>
              ))
            ): (
              <p>No suggestions available</p>
            )}
          </SearchDropdown>
        )}
      </DesktopSearchContainer>
    </HeaderContainer>
  );
};

export default Search;
