import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const LoginError = () => {
  const navigate = useNavigate();

  // Function to handle navigation back to login or home page
  const handleLoginRedirect = () => {
    navigate('/login'); // Adjust this route to match your login page route
  };

  return (
    <Container>
      <ErrorMessage>You need to login to see this page</ErrorMessage>
      <Button onClick={handleLoginRedirect}>Go to Login</Button>
    </Container>
  );
};

// Styled-components for the LoginError component
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background || '#f8f9fa'};
  text-align: center;
`;

const ErrorMessage = styled.h2`
  color: ${({ theme }) => theme.colors.error || '#ff4d4d'};
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.primary || '#007bff'};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover || '#0056b3'};
  }
`;

export default LoginError;
