import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useAuth } from '../../AuthProvider';
const SigninContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('https://cdn.shopify.com/s/files/1/0851/4607/5431/files/Untitled_design_20.png?v=1725961182');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const SigninBox = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 300px;
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0.5);
  color: white;
`;
const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const Input = styled.input`
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.extralight};
`;
const EyeIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${({ theme }) => theme.colors.medium};
`;
const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.colors.medium};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
  &:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
`;
const ForgotPassword = styled.div`
  text-align: left;
  margin-top: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #007BFF;
  &:hover {
    text-decoration: underline;
  }
`;
const Error = styled.div`
  color: red;
  margin-bottom: 15px;
`;
const Signin = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { signin } = useAuth();  // Use signin from AuthProvider
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset the error message
    setLoading(true); // Set loading state
    try {
      await signin(email, password);  // Call signin from AuthProvider
      navigate('/Common/Sell'); // Redirect to Sell page after login
    } catch (error) {
      console.error("Error during login:", error.code, error.message);
      // Specific error handling based on Firebase error codes
      switch (error.code) {
        case 'auth/invalid-email':
          setError('Invalid email address.');
          break;
        case 'auth/user-disabled':
          setError('This user has been disabled.');
          break;
        case 'auth/user-not-found':
          setError('No user found with this email.');
          break;
        case 'auth/wrong-password':
          setError('Incorrect password. Please try again.');
          break;
        default:
          setError(`Login failed: ${error.message}`);
      }
    } finally {
      setLoading(false); // Stop loading state
    }
  };
  const handleForgotPassword = () => {
    navigate('/forgotpassword', { state: { email } });
  };
  const handleSignUp = () => {
    navigate('/signup');
  };
  return (
    <SigninContainer>
      <SigninBox>
        <h2>Log In</h2>
        {error && <Error>{error}</Error>}
        <form onSubmit={handleLogin}>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <InputWrapper>
            <Input
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <EyeIcon onClick={togglePasswordVisibility} aria-label={passwordVisible ? 'Hide password' : 'Show password'}>
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </EyeIcon>
          </InputWrapper>
          <ForgotPassword onClick={handleForgotPassword}>Forgot Password?</ForgotPassword>
          <Button type="submit" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </Button>
          <Button type="button" onClick={handleSignUp}>
            Sign Up
          </Button>
        </form>
      </SigninBox>
    </SigninContainer>
  );
};
export default Signin;









