// BackToTop.js
import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import styled from 'styled-components';

// Styled components
const BackToTopWrapper = styled.div`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  @media (max-width: 768px) {
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
  }

  @media (max-width: 480px) {
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
  }
`;

const ProgressRing = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Circle = styled.circle`
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

const Icon = styled(FaArrowUp)`
  color: #F7AD2B;
  font-size: 24px;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const calculateScrollProgress = () => {
    const scrollTop = window.pageYOffset;
    const docHeight = document.documentElement.scrollHeight - window.innerHeight;
    const progress = (scrollTop / docHeight) * 100;
    setScrollProgress(progress);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      toggleVisibility();
      calculateScrollProgress();
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <BackToTopWrapper className={isVisible ? 'visible' : ''} onClick={scrollToTop}>
      <ProgressRing width="60" height="60" viewBox="0 0 60 60">
        <Circle className="progress-ring__circle" stroke="black" strokeWidth="4" fill="transparent" r="28" cx="30" cy="30" />
        <Circle
          className="progress-ring__circle--progress"
          stroke="#F7AD2B"
          strokeWidth="4"
          fill="transparent"
          r="28"
          cx="30"
          cy="30"
          style={{ strokeDasharray: '175.84', strokeDashoffset: 175.84 - (175.84 * scrollProgress) / 100 }}
        />
      </ProgressRing>
      <Icon />
    </BackToTopWrapper>
  );
};

export default BackToTop;
