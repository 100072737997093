import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../Loader';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.light};
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const ListingSection = styled.div`
  flex: 1;
  padding: 20px 40px;
  background-color: ${({ theme }) => theme.colors.light};
  @media (max-width: 1024px) {
    padding: 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h2`
  margin-right: 20px;
`;

export const Dropdown = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  width: 100%;
`;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

export const MediaContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: 180px;
  margin-bottom: 15px;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

export const VideoThumbnail = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

export const Description = styled.p`
  font-size: 14px;
  font-family: 'lora';
  color: #666;
  margin: 8px 0;
  text-align: center;
  strong {
    color: #333;
  }
`;

export const ViewButton = styled.button`
  background-color: ${({ theme }) => theme.colors.medium};
  color: white;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  &:hover {
    background-color:${({ theme }) => theme.colors.dark};
  }
`;

const NoPropertyMessage = styled.p`
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
  color: ${({ theme }) => theme.colors.dark};
`;

const LoadingMessage = styled.p`
  font-weight: bold;
  font-family: 'lora';
  text-align: center;
  margin: 20px 0;
  color: ${({ theme }) => theme.colors.dark};
`;

const SearchInput = styled.input`
  width: 100%;
  max-width: 400px; /* Set a maximum width for the input */
  padding: 10px 15px; /* Padding for a better touch area */
  margin-right: 20px; /* Spacing between the input and dropdown */
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: border-color 0.3s, box-shadow 0.3s; /* Transition for hover effects */

  &:focus {
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Shadow on focus */
    outline: none; /* Remove outline */
  }

  &::placeholder {
    color: #aaa; /* Placeholder color */
  }
`;

const PropertyListingAdmin = () => {
  const { title } = useParams();
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [types, setTypes] = useState('all');
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query

  const navigate = useNavigate();

  const fetchProperties = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/propertyListAdmin`);
      setList(response.data);
      setFilteredList(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  useEffect(() => {
    // Filter based on types
    const filteredByType = types === 'all' ? list : list.filter(item => item.types.toLowerCase() === types.toLowerCase());
    
    // Filter based on search query
    const filteredByCode = searchQuery ? filteredByType.filter(item => item.code.toLowerCase().includes(searchQuery.toLowerCase())) : filteredByType;
    
    setFilteredList(filteredByCode);
  }, [types, list, searchQuery]);

  return (
    <Container>
      <ListingSection>
        <Header>
          <TitleContainer>
            <Title>All Properties</Title>
            <Dropdown value={types} onChange={(e) => setTypes(e.target.value)}>
              <option value="all">All Types</option>
              <option value="sell">Sell</option>
              <option value="rent">Rent</option>
              <option value="lease">Lease</option>
            </Dropdown>
          </TitleContainer>
          {/* Search Input */}
          <SearchInput
            type="text"
            placeholder="Search by Code ID"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Header>

        {loading ? (
          <LoadingMessage><Loader /></LoadingMessage>
        ) : (
          <Grid>
            {filteredList.length > 0 ? (
              filteredList.map((item, index) => (
                <Card key={index}>
                  <MediaContainer>
                    {item.files && item.files.length > 0 ? (
                      item.files[0].toLowerCase().includes('.mp4') ? ( // Use includes to check for '.mp4'
                        <VideoThumbnail
                          src={item.files[0]} // Use the first file if it's a video
                          // controls 
                        />
                      ) : (
                        <Image
                          src={item.files[0]} // Use the first file if it's not a video
                          alt={item.propertyName}
                        />
                      )
                    ) : (
                      <Image
                        src='https://via.placeholder.com/150' // Default placeholder image
                        alt={item.propertyName}
                      />
                    )}
                  </MediaContainer>

                  <Description><strong>Code ID: </strong>{item.code}</Description>
                  <Description><strong>Property Name: </strong>{item.propertyName}</Description>
                  <Description><strong>Property Type: </strong>{item.propertyType}</Description>
                  <Description><strong>Types: </strong>{item.types}</Description>
                  <Description><strong>Budget: </strong>{item.budget} {item.budgetMethod}</Description>
                  <ViewButton onClick={() => navigate(`/individualadmin/${item._id}`)}>View Details</ViewButton>
                </Card>
              ))
            ) : (
              <NoPropertyMessage>No properties found for the selected type or search query.</NoPropertyMessage>
            )}
          </Grid>
        )}
      </ListingSection>
    </Container>
  );
};

export default PropertyListingAdmin;