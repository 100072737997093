import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { faHome, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import SimilarProperties from "./SimilarProperties";
import { useAuth } from '../AuthProvider';
// Fullscreen Modal styling for image viewing
const FullscreenModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalImage = styled.img`
  max-width: 90%;
  max-height: 80%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
`;

const ModalCloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: white;
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  ${(props) => (props.direction === "left" ? "left: 20px;" : "right: 20px;")}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  color: white;
  z-index: 100;
`;

const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  background-color: #e7e1d4;
`;

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LargeImageContainer = styled.div`
  position: relative;
`;

const LargeImage = styled.img`
  width: 850px;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
`;

const SmallImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SmallImage = styled.div`
  position: relative;
  width: 150px;
  height: 100px;
  border-radius: 10px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  ${(props) =>
        props.showOverlay &&
        `
  &::after {
    content: '+${props.overlayText}';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
  }
`}
`;

const PropertyDetailsContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding: 10px;
`;

// const DetailLabel = styled.div`
//   font-weight: bold;
// `;

// const DetailValue = styled.div`
//   text-align: right;
// `;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  background: #954535;
  color: #e7e1d4;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

// const ButtonIcon = styled.span`
//   margin-right: 5px;
// `;

const Detail = styled.p`
  margin: 10px 0;
  font-size: 18px;
`;

const BoldLabel = styled.span`
  font-weight: bold;
`;

const HeartIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  color: ${(props) => (props.isActive ? "red" : "gray")};
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust spacing between elements */
  margin-bottom: 30px; /* Adjust spacing below the header */
`;

const Section = styled.div`
  background-color: #e7e1d4; /* Background color */
  padding: 20px;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d0bda6; /* Line color */
  margin: 20px 0;
`;

const SectionTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;

const SectionContent = styled.p`
  font-size: 18px;
  color: #555;
`;

const SimilarPropertiesWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const SimilarPropertyCard = styled.div`
  position: relative;
  width: 300px;
  height: 350px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  text-align: center;
  cursor: pointer; /* Change cursor to pointer on hover */

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  p {
    padding-left: 10%;
    text-align: left;
    font-size: 14px;
    margin: 5px 0;
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 8px;
    color: #333;
  }

  /* Glass-effect slider that appears from the bottom */
  &::after {
    content: "View Property";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background: rgba(231, 225, 212, 0.4); /* Semi-transparent background */
    backdrop-filter: blur(3px); /* Frosted glass effect */
    color: #2a2e27;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(100%);
    transition: transform 0.3s ease; /* Smooth transition */
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
  }

  /* Show the slider when hovering over the card */
  &:hover::after {
    transform: translateY(0);
  }
`;

const GlassEffectCircle = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent background */
  backdrop-filter: blur(8px); /* Frosted glass effect */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 10;
`;

const HeartIconWrapper = styled.div`
  font-size: 24px;
  color: white;
`;

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Black with transparency */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's on top of other content */
`;

const PopupContent = styled.div`
  position: relative;
  background: rgba(255, 255, 255, 0.9); /* White with slight transparency */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: red;
  cursor: pointer;
`;


const IndividualProperty2 = () => {
    const { id } = useParams();
    const { propertyId } = useParams();
    const [property, setProperty] = useState(null);
    const [similarProperties, setSimilarProperties] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [heartStates, setHeartStates] = useState({});
    const touchStartX = useRef(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const navigate = useNavigate();
    const { user } = useAuth(); // Get current user
    const [isWishlisted, setIsWishlisted] = useState(false);

    useEffect(() => {
        const fetchPropertyDetails = async () => {
            try {
                // Fetch the main property details
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/property/${propertyId}`);
                setProperty(response.data);

                // Fetch similar properties based on location and propertyName
                const similarResponse = await axios.get(`${process.env.REACT_APP_API_URL}/similarProperties/${propertyId}`);
                setSimilarProperties(similarResponse.data);

                // Initialize heart states for similar properties
                const initialHeartStates = similarResponse.data.reduce((acc, prop) => {
                    acc[prop._id] = false; // Assuming false is the initial state
                    return acc;
                }, {});
                setHeartStates(initialHeartStates);
            } catch (error) {
                console.error("Error fetching property details or similar properties:", error);
            }
        };

        fetchPropertyDetails();
    }, [propertyId]); // Use propertyId instead of id to keep both fetches in sync
    
    useEffect(() => {
        if (property && user) { // Check if property and user are available
            const checkWishlistStatus = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/wishlist/check/${property._id}`, {
                        headers: {
                            'Authorization': `Bearer ${user.token}`
                        }
                    });
                    setIsWishlisted(response.data.isWishlisted);
                } catch (error) {
                    console.error('Error checking wishlist status:', error);
                }
            };

            checkWishlistStatus();
        }
    }, [property, user]); 

 


    if (!property) {
        return <p>Loading...</p>;
    }

    const { files = [] } = property;
    const remainingImagesCount = files.length - 3;

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === files.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? files.length - 1 : prevIndex - 1
        );
    };

    const openModal = (index) => {
        setCurrentImageIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
        if (touchStartX.current === null) return;
        const touchEndX = e.changedTouches[0].clientX;
        if (touchStartX.current - touchEndX > 50) {
            nextImage();
        }
        if (touchStartX.current - touchEndX < -50) {
            prevImage();
        }
        touchStartX.current = null;
    };

    const toggleHeart = async (propertyId) => {
        try {
            const response = await axios.post(`/api/wishlist/toggle/${propertyId}`, {}, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            setIsWishlisted(response.data.isWishlisted);
        } catch (error) {
            console.error('Error toggling wishlist status:', error);
        }
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    return (
        <div>
            <Container>
                {/* Image section */}
                <ImagesWrapper>
                    <SmallImagesContainer>
                        {files.slice(0, 3).map((image, index) => (
                            <SmallImage key={index} onClick={() => openModal(index)}>
                                <img src={image} alt={`Property ${index + 1}`} />
                            </SmallImage>
                        ))}
                        {remainingImagesCount > 0 && (
                            <SmallImage
                                overlayText={remainingImagesCount}
                                showOverlay={true}
                                onClick={() => openModal(3)}
                            >
                                <img
                                    src={files[3]}
                                    alt={`Remaining ${remainingImagesCount} images`}
                                />
                            </SmallImage>
                        )}
                    </SmallImagesContainer>
                </ImagesWrapper>

                <LargeImageContainer onClick={() => openModal(currentImageIndex)}>
                    <Arrow
                        direction="left"
                        onClick={(e) => {
                            e.stopPropagation();
                            prevImage();
                        }}
                    >
                        &#10094;
                    </Arrow>
                    <LargeImage
                        src={files[currentImageIndex]}
                        alt="Property large view"
                    />
                    <Arrow
                        direction="right"
                        onClick={(e) => {
                            e.stopPropagation();
                            nextImage();
                        }}
                    >
                        &#10095;
                    </Arrow>
                </LargeImageContainer>
                <div>
                <HeaderContainer>
                        <h2 style={{ marginRight: "20px" }}>
                            {property.propertyName || "Property Details"}
                        </h2>
                        <FontAwesomeIcon
                            icon={faHeart}
                            style={{ color: isWishlisted ? 'red' : 'grey', cursor: 'pointer' }}
                            onClick={() => toggleHeart(property._id)}
                        />
                        <FontAwesomeIcon
                            icon={faShareAlt}
                            style={{ fontSize: "24px", marginLeft: "20px" }}
                        />
                    </HeaderContainer>

                    <PropertyDetailsContainer>
                        <Detail>
                            <BoldLabel>Property Type:</BoldLabel> {property.propertyType}
                        </Detail>
                        <Detail>
                            <BoldLabel>No. of sq.ft:</BoldLabel> {property.numberOfSqFeet}
                        </Detail>
                        <Detail>
                            <BoldLabel>Build Area:</BoldLabel> {property.buildUpArea}
                        </Detail>
                        <Detail>
                            <BoldLabel>Age of Property:</BoldLabel> {property.ageOfProperty}
                        </Detail>
                        <Detail>
                            <BoldLabel>No. of Bathrooms:</BoldLabel> {property.numberOfBathrooms}
                        </Detail>
                        <Detail>
                            <BoldLabel>No. of Rooms:</BoldLabel> {property.numberOfRooms}
                        </Detail>
                        <Detail>
                            <BoldLabel>Parking:</BoldLabel>{" "}
                            {property.parking ? "Available" : "Not Available"}
                        </Detail>
                        <Detail>
                            <BoldLabel>Lift:</BoldLabel>{" "}
                            {property.lift ? "Available" : "Not Available"}
                        </Detail>
                        <Detail>
                            <BoldLabel>Type:</BoldLabel> {property.types}
                        </Detail>
                        <Detail>
                            <BoldLabel>Location:</BoldLabel> {property.city}
                        </Detail>
                        <div>
                            <ButtonGroup>
                                <Button onClick={togglePopup}>Contact Owner</Button>
                            </ButtonGroup>
                        </div>
                    </PropertyDetailsContainer>
                </div>

                {isPopupOpen && (
                    <OverlayContainer>
                        <PopupContent>
                            <CloseButton onClick={togglePopup}>&times;</CloseButton>
                            <h3>Contact Owner</h3>
                            <p>
                                <strong>Owner Name:</strong> {property.contactOwnerName || "N/A"}
                            </p>
                            <p>
                                <strong>Phone Number:</strong> {property.contactOwnerPhone || "N/A"}
                            </p>
                            <p>
                                <strong>Property Address:</strong> {property.propertyAddress || "N/A"}, {property.city} - {property.zipCode}
                            </p>
                        </PopupContent>
                    </OverlayContainer>
                )}

                {isModalOpen && (
                    <FullscreenModal
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}
                    >
                        <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
                        <Arrow direction="left" onClick={prevImage}>
                            &#10094;
                        </Arrow>
                        <ModalImage
                            src={files[currentImageIndex]}
                            alt="Fullscreen Property view"
                        />
                        <Arrow direction="right" onClick={nextImage}>
                            &#10095;
                        </Arrow>
                    </FullscreenModal>
                )}
            </Container>
            {/* <div>
                <Section>
                    <HorizontalLine />
                    <SectionTitle>About Property</SectionTitle>
                    <SectionContent>
                        
                        This property is a beautiful, spacious home perfect for families or
                        individuals seeking comfort and modern living.
                    </SectionContent>
                </Section>

          
                <Section>
                    <HorizontalLine />
                    <SectionTitle>Features</SectionTitle>
                    <SectionContent>
                        
                        - 3 Bedrooms
                        <br />
                        - 2 Bathrooms
                        <br />
                        - Swimming Pool
                        <br />
                        - Spacious Garden
                        <br />- Modern Kitchen
                    </SectionContent>
                </Section>


                <Section>
                    <HorizontalLine />
                    <SectionTitle>Similar Properties</SectionTitle>
                    <SimilarPropertiesWrapper>
                        {similarProperties.map((prop) => (
                            <SimilarPropertyCard
                                key={prop._id}
                                onClick={() => navigate(`/similar-product/${prop._id}`)} // Navigate to SimilarProduct page with property ID
                            >
                                <GlassEffectCircle>
                                    <HeartIconWrapper>
                                        <HeartIcon
                                            icon={faHeart}
                                            isActive={heartStates[prop._id]}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent click event from bubbling up to the card
                                                toggleHeart(prop._id);
                                            }}
                                        />
                                    </HeartIconWrapper>
                                </GlassEffectCircle>
                                <img src={prop.files[0]} alt={prop.propertyName} />
                                <p>
                                    <FontAwesomeIcon icon={faHome} className="icon" />
                                    {prop.propertyName}
                                </p>
                                <p>₹ {prop.budget}</p>
                                <p>
                                    <FontAwesomeIcon icon={faLocationDot} className="icon" />
                                    {prop.city}
                                </p>
                            </SimilarPropertyCard>
                        ))}
                    </SimilarPropertiesWrapper>

                </Section>
            </div> */}
        </div>
    );
};

export default IndividualProperty2;
