// src/theme.js

export const theme = {
    colors: {
      light: "#E7E1D4",
      dark: "#4681F4",
      medium: "#3350A6",
      extralight: "#F2F8FC",
    }
  };
  