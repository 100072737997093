import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../AuthProvider';
import { useNavigate } from 'react-router-dom';

// Styled components
const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('https://cdn.shopify.com/s/files/1/0851/4607/5431/files/Untitled_design_20.png?v=1725961182');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ForgotPasswordBox = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 300px;
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0.5);
  color: white;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.extralight};
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.colors.medium};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
  &:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
`;

const Error = styled.div`
  color: red;
  margin-bottom: 15px;
`;

const SuccessMessage = styled.div`
  color: green;
  margin-bottom: 15px;
`;

// Pop-up styled component
const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

// Main component
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { resetPassword } = useAuth();
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      await resetPassword(email);
      setShowPopup(true); // Show the pop-up on success
    } catch (error) {
      console.error('Error during password reset:', error);
      setError('Failed to send password reset email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSignin = () => {
    navigate('/signin');
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <ForgotPasswordContainer>
      <ForgotPasswordBox>
        <h2>Reset Password</h2>
        {error && <Error>{error}</Error>}
        <form onSubmit={handleResetPassword}>
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button type="submit" disabled={loading}>
            {loading ? 'Sending...' : 'Reset Password'}
          </Button>
          <Button onClick={handleSignin}>Login</Button>
        </form>
      </ForgotPasswordBox>

      {/* Popup Message */}
      {showPopup && (
        <>
          <Overlay onClick={handleClosePopup} />
          <Popup>
            <h3>Password Reset Email Sent!</h3>
            <p>Please check your inbox for the reset link.</p>
            <Button onClick={handleClosePopup}>Close</Button>
          </Popup>
        </>
      )}
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;
