import React from "react";
import styled from "styled-components";
const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.light};
`;
const ImageContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }
`;
const InfoFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const LeftContainer = styled.div`
  flex: 1;
  padding: 30px;
  background: linear-gradient(135deg, #F5D0C5, #F7E4DB, #B6E2E9, #D2C4FC
); /* Same pastel gradient */
  color: ${({ theme }) => theme.colors.dark};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid black;
  h2, h3, p {
    margin: 0 0 15px;
    font-size: 18px; /* Increased font size */
  }
  h2 {
    font-size: 24px; /* Increased size for headings */
    font-weight: bold;
  }
  h3 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
  }
  .contact-details {
    margin-bottom: 20px;
    p {
      display: flex;
      align-items: center;
      font-size: 16px;
      i {
        margin-right: 10px;
        font-size: 18px;
      }
    }
  }
  .social-icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    a {
      color: ${({ theme }) => theme.colors.dark};
      font-size: 24px; /* Increased size for social icons */
      margin: 0 15px;
      text-decoration: none;
      &:hover {
        color: ${({ theme }) => theme.colors.medium};
      }
    }
  }
`;
const RightContainer = styled.div`
  flex: 1;
  padding: 20px;
  form {
    display: flex;
    flex-direction: column;
    .form-group {
      margin-bottom: 20px;
      label {
        display: block;
        margin-bottom: 5px;  /* Adjust margin for label */
        font-weight: bold;
        font-size: 16px;
      }
      input, textarea {
        width: 97%;
        padding: 12px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
      textarea {
        resize: vertical;
        height: 120px;
         /* Adjust margin between label and textarea */
      }
    }
    .submit-button {
      padding: 15px;
      font-size: 16px;
      background-color: ${({ theme }) => theme.colors.medium};
      color: ${({ theme }) => theme.colors.light};
      border: none;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: ${({ theme }) => theme.colors.dark};
      }
    }
  }
`;

const ContactMap = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 20px 0;
  iframe {
    width: 100%;
    height: 400px;
    border: none;
    border-radius: 10px;
  }
`;
const Contact = () => {
  window.scrollTo({top:0, behavior: 'smooth'})
  return (
    <ContactContainer>
      {/* <ImageContainer>
        <img src="/Contact.png" alt="Contact Banner" />
      </ImageContainer> */}
      <InfoFormContainer>
        <LeftContainer>
          <h1>Contact Information</h1>
          <p><h3>Dreams come true !!</h3></p>
          <h1>Akshaya Lands</h1>
          <div className="contact-details">
            <p>
              <i className="fas fa-phone-alt"></i>  6385324990/ 9843353364
            </p>
            <p>
              <i className="fas fa-envelope"></i>  akshayalands.tup@gmail.com
            </p>
            <p>
              <i className="fas fa-map-marker-alt"></i>
              17/4. Town Extension, 2nd Street,<br />
              Cotton Market Post, <br />
              Tirupur- 641604
            </p>
          </div>
          <div className="social-icons">
  <a href="https://www.facebook.com/profile.php?id=61561717779027" target="_blank" rel="noreferrer">
    <i className="fab fa-facebook-f"></i>
  </a>
  <a href="https://www.instagram.com/akashaya_lands/?igsh=MWt3b3k3MWlheTBwcQ%3D%3D" target="_blank" rel="noreferrer">
    <i className="fab fa-instagram"></i>
  </a>
  <a href="https://www.youtube.com/@akshayalands" target="_blank" rel="noreferrer">
    <i className="fab fa-youtube"></i>
  </a>
</div>
        </LeftContainer>
        <RightContainer>
        <form action="https://api.web3forms.com/submit" method="POST">
            <input type="hidden" name="access_key" value="a8c9e7ee-1e8b-45d2-be3e-d3e1147851d8" />

            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" id="name" name="name" placeholder="Enter your name" required />
            </div>

            <div class="form-group">
              <label for="surname">Surname</label>
              <input type="text" id="surname" name="surname" placeholder="Enter your surname" required />
            </div>

            <div class="form-group">
              <label for="phone">Phone Number</label>
              <input type="tel" id="phone" name="phone" placeholder="Enter your phone number" required />
            </div>

            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" id="email" name="email" placeholder="Enter your email" required />
            </div>
            <div class="form-group">
              <label for="message">Message</label>
              <textarea id="message" name="message" placeholder="Enter your message" required></textarea>
            </div>
            <button type="submit" class="submit-button">Submit</button>
          </form>
        </RightContainer>
      </InfoFormContainer>
      <ContactMap>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3915.2265241849564!2d77.350156!3d11.096488!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba90795f873e4b3%3A0x268efbad6223edff!2s1%2C%20Town%20Extension%20Second%20St%2C%20Town%20Extn%2C%20Tiruppur%2C%20Tamil%20Nadu%20641604%2C%20India!5e0!3m2!1sen!2sus!4v1725620449156!5m2!1sen!2sus"
          allowFullScreen=""
          loading="lazy"
          title="Akshaya Lands Location"
        ></iframe>
      </ContactMap>
    </ContactContainer>
  );
};
export default Contact;