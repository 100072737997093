

// ind 2 page contact owner blur



import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faHeart, faShareAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import useMediaQuery from '../useMediaQuery';
import { useWishlist } from '../WishlistContext';
import { useAuth } from '../AuthProvider';
import { BsHeart, BsHeartFill } from "react-icons/bs";
import SimilarProperties from "./SimilarProperties";
import Loader from './Loader';
// Styled components
const FullscreenModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalImage = styled.img`
  max-width: 90%;
  max-height: 80%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
`;

const ModalCloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: white;
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  ${(props) => (props.direction === 'left' ? 'left: 20px;' : 'right: 20px;')}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  color: white;
  //z-index: 100;
`;

const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  background-color: #e7e1d4;
  flex-direction: row; /* Changed to row to display items in a row */

  @media (max-width: 768px) {
    flex-direction: column; /* Keep column layout for mobile */
  }
`;

const MainContainer = styled.div`
  background-color: #e7e1d4;

  @media (max-width: 768px) {
    flex-direction: column; /* Keep column layout for mobile */
  }
`;

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LargeImageContainer = styled.div`
  position: relative;
  width: 50%;
  height: 500px; /* Set a fixed height */
  overflow: hidden; /* Prevent overflow */

  @media(max-width: 768px) {
    width: 100%;
  }
`;

const LargeImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  }
`;
const SmallImagesContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;

  @media(min-width: 768px) {
    flex-direction: column;
  }
`;

const SmallImage = styled.div`
  position: relative;
  width: 100px;
  height: 70px;
  border-radius: 10px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  ${(props) =>
        props.showOverlay &&
        `
  &::after {
    content: '+${props.overlayText}';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
  }
`}
`;

const PropertyDetailsContainer = styled.div`
  flex: 2;
  background-color: #ffffff; /* Subtle background for separation */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between; /* Adjusts spacing between buttons */
  align-items: center; /* Aligns buttons vertically in the center */
  margin-top: 20px;
  gap: 10px; /* Adds space between buttons */
  flex-wrap: nowrap; /* Prevents buttons from wrapping onto the next line */
  
  @media(max-width: 768px) {
    display: grid;
    flex-wrap: wrap; /* Allow wrapping for smaller screens if needed */
  }
`;

const Button = styled.button`
  background-color: ${(props) => props.theme.colors.medium};
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;

  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.dark};
    transform: translateY(-2px);
  }

  svg {
    margin-right: 8px;  // Margin for icon
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
    font-size: 14px;
  }
`;



const Detail = styled.p`
  margin: 10px 0;
  font-size: 18px;
  font-family: 'lora';
  font-weight: 600; /* Adjust font weight for better readability */
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
`;

const BoldLabel = styled.span`
  font-weight: bold;
  color: #92443A; /* Add custom color for labels */
  font-size: 19px;
  font-family: 'lora';
`;
const StyledHeart = styled(BsHeartFill)`
  font-size: 24px;
  color: ${(props) => (props.isActive ? 'red' : 'white')};
  cursor: pointer;
`;

// Overlay styles for the popup
const OverlayContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const PopupContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media (max-width: 768px) {
        padding: 15px;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
`;

const Title = styled.h3`
    text-align: center;
    font-size: 1.5rem;
    margin: 0;
`;

const Description = styled.p`
    font-size: 1rem;
    font-family: 'lora';
    text-align: center;
    margin: 0;
    padding-bottom: 20px;
`;

const PlanOptions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;
`;

const PlanOption = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    border: 2px solid ${({ selected }) => (selected ? '#007BFF' : '#ccc')}; /* Highlight when selected */
    background-color: ${({ selected }) => (selected ? '#e6f0ff' : 'transparent')}; /* Light blue background when selected */
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        border-color: #007BFF; /* On hover, change border color */
    }

    input {
        accent-color: #007BFF; /* Radio button color */
    }
`;

const PlanLabel = styled.span`
    font-size: 1rem;
    color: ${({ selected }) => (selected ? '#007BFF' : '#333')}; /* Text color change when selected */
`;

const StyledButton = styled.button`
    background-color: #007BFF;
    color: white;
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    &:hover {
        background-color: #0056b3;
    }
`;

// Button Group and Wrapper (existing styles)
// const ButtonGroupWrapper = styled.div`
//     /* Adjust padding and alignment for responsiveness */
//     padding: 10px;
// `;

// const ButtonGroup = styled.div`
//     display: flex;
//     justify-content: space-between;
//     gap: 10px;
//     flex-wrap: wrap;
//     @media (max-width: 768px) {
//         flex-direction: column;
//     }
// `;

const ShowMoreButton = styled.button`
  background: none;
  border: none;
  margin-left: 80%;
  color: #954535;
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
  transition: color 0.3s ease;

  &:hover {
    color: #92443a;
  }

  @media(max-width: 768px) {
    margin-left: 0;
  }
`;

const ButtonGroupWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 30px
`;
const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between; /* Adjust to fit your design */
  padding: 10px;
  background-color: #f9f9f9; /* Optional: give it a background for contrast */
  border-radius: 8px; /* Optional: add rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add some shadow for depth */
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const ModalContainer = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
`;

export const ModalButton = styled.button`
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;
const VideoThumbnail = styled.video`
    width: 100%;
    height: auto;
`;

const ImageThumbnail = styled.img`
    width: 100%;
    height: auto;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the container */
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Optional, to provide a background */
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures the image fits within the container */
  display: block;
`;

const ModalVideo = styled.video`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  controls;
`;

const IndividualProperty5 = () => {
    const { propertyId } = useParams();
    const navigate = useNavigate();
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isHeartActive, setIsHeartActive] = useState(false);
    const touchStartX = useRef(null);
    const isTabletOrMobile = useMediaQuery('(max-width: 768px)');
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const { addToWishlist, removeFromWishlist } = useWishlist();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { wishlist, setWishlist } = useWishlist();
    const [selectedPlan, setSelectedPlan] = useState('');
    const { isAuthenticated } = useAuth();
    const [plans, setPlans] = useState([]);
    const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [showLoginModal, setShowLoginModal] = useState(false);
   

    

    const handlePlanChange = (planName) => {
        setSelectedPlan(planName);
    };

    const handlePayment = () => {
        if (!selectedPlan) {
            setModalMessage('Please select a plan.');  // Set the message for the modal
            setIsPlanModalOpen(true);  // Open the modal
            return;
        }
        console.log(`Payment process initiated for ${selectedPlan}`);
    };

    const [showMore, setShowMore] = useState(false);
    // Function to check if user is logged in before showing the subscription popup
    const handleContactOwnerClick = () => {
        if (!isDisabled) {
            // Handle contact owner action
            console.log('Contact Owner button clicked!');
        } else {
            console.log('Contact Owner button is disabled.');
        }
        // if (!isAuthenticated) {
        //     // If user is not logged in, redirect them to the login page
        //     if (window.confirm('You need to login to contact the owner. Do you want to login now?')) {
        //         navigate('/signin');
        //     }
        // } else {
        //     // If the user is logged in, show the subscription popup
        //     togglePopup();
        // }
    };

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/plans`);
                setPlans(response.data);
            } catch (error) {
                console.error('Error fetching plans:', error);
            }
        };

        fetchPlans();
    }, []);


    const handleShare = () => {
        const shareData = {
            title: property.title, // Title of the property
            text: `Check out this property: ${property.title}`, // Description of the property
            url: window.location.href // Current URL to share (or property specific URL)
        };

        // Check if the share API is supported
        if (navigator.share) {
            navigator.share(shareData)
                .then(() => console.log('Property shared successfully'))
                .catch((error) => console.error('Error sharing:', error));
        } else {
            // Fallback if share API is not supported (copy URL to clipboard, alert message, etc.)
            alert('Sharing is not supported on this browser. You can copy the URL manually.');
        }
    };
    // Utility function to retrieve wishlist from localStorage
    const getWishlistFromLocalStorage = () => {
        const storedWishlist = localStorage.getItem('wishlist');
        return storedWishlist ? JSON.parse(storedWishlist) : [];
    };
    // Retrieve wishlist from localStorage
    // const getWishlistFromLocalStorage = () => {
    //   return JSON.parse(localStorage.getItem('wishlist')) || [];
    // };
    const handleAddToWishlist = () => {
        addToWishlist(property);
    };

    // Fetch property details

    useEffect(() => {
        const fetchPropertyDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/properties/${propertyId}`);
                const fetchedProperty = response.data;
                setProperty(fetchedProperty);

                // Get the wishlist and ensure it's an array
                const wishlist = getWishlistFromLocalStorage();
                if (!Array.isArray(wishlist)) {
                    console.error('Wishlist is not an array:', wishlist);
                }

                // Check if the property is wishlisted
                const isWishlisted = wishlist.some(prop => prop && prop._id === propertyId); // Check if prop is not null/undefined
                setIsHeartActive(isWishlisted);
            } catch (error) {
                console.error('Error fetching property details:', error);
                setError('Error Loading Property..!');
            } finally {
                setLoading(false);
            }
        };

        fetchPropertyDetails();
    }, [propertyId]);

    // // Utility function to retrieve wishlist from localStorage
    // const getWishlistFromLocalStorage = () => {
    //     const storedWishlist = localStorage.getItem('wishlist');
    //     return storedWishlist ? JSON.parse(storedWishlist) : [];
    // };

    // Utility function to save wishlist to localStorage
    const saveWishlistToLocalStorage = (wishlist) => {
        localStorage.setItem('wishlist', JSON.stringify(wishlist));
    };


    if (loading) return <Loader />;
    if (error) return <p>{error}</p>;
    if (!property) return <p>No property details found.</p>;

    const { files = [] } = property;
    const remainingImagesCount = files.length - 3;

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === files.length - 1 ? 0 : prevIndex + 1));
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? files.length - 1 : prevIndex - 1));
    };

    const openModal = (index) => {
        setCurrentImageIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
        if (touchStartX.current === null) return;
        const touchEndX = e.changedTouches[0].clientX;
        if (touchStartX.current - touchEndX > 50) {
            nextImage();
        }
        if (touchStartX.current - touchEndX < -50) {
            prevImage();
        }
        touchStartX.current = null;
    };

    const toggleHeart = async () => {
        // Check if the user is logged in
        if (!userDetails || !userDetails.email) {
            // alert("Please log in to add properties to your wishlist."); // You can replace this with a modal if preferred
            setShowLoginModal(true);
            return; // Exit early if user is not logged in
        }

        // Ensure `property` is valid
        if (!property || !property._id) {
            console.error("Invalid property:", property); // Log the invalid property
            setError("Invalid property.");
            return; // Exit the function early if property is invalid
        }

        try {
            const endpoint = isHeartActive
                ? `${process.env.REACT_APP_API_URL}/remove-from-wishlist`
                : `${process.env.REACT_APP_API_URL}/add-to-wishlist`;

            const response = await axios.post(endpoint, {
                email: userDetails.email,
                propertyId: property._id
            });

            if (response.status === 200) {
                const wishlist = getWishlistFromLocalStorage() || [];

                if (!Array.isArray(wishlist)) {
                    console.error("Invalid wishlist data.");
                    setError("Failed to load wishlist data.");
                    return;
                }

                // Filter only valid properties (exclude null or undefined entries)
                const validWishlist = wishlist.filter(item => item && item._id);

                let updatedWishlist;

                if (isHeartActive) {
                    // Remove from wishlist (ensure `property._id` is valid)
                    updatedWishlist = validWishlist.filter(prop => prop._id !== property._id);
                } else {
                    // Add to wishlist (ensure `property` is valid)
                    updatedWishlist = [...validWishlist, property];
                }

                // Save the updated wishlist to local storage
                saveWishlistToLocalStorage(updatedWishlist);

                // Update the context and toggle the heart state
                setWishlist(updatedWishlist);
                setIsHeartActive(!isHeartActive);
            } else {
                console.error('Failed to update wishlist:', response.data);
                setError('Failed to update wishlist.');
            }
        } catch (error) {
            console.error('Error updating wishlist:', error);
            setError('Failed to update wishlist.');
        }
    };

    const closeModal1 = () => {
        setShowLoginModal(false); // Hide the modal
    };



    const deleteProperty = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/properties/${propertyId}`);
            navigate('/profile'); // Navigate to the properties list or another appropriate page
        } catch (error) {
            console.error('Error deleting property:', error.response || error.message || error);
            setError('Failed to delete property.');
        }
    };

    // Navigate to EditProperty.js with the propertyId
    const handleEdit = (property) => {
        // Navigate to the edit page with the property state
        navigate(`/editProperty/${property._id}`, { state: { property } });
    };


    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const isVideo = (file) => {
        return file && (file.endsWith('.mp4') || file.endsWith('.avi') || file.endsWith('.mov') || file.endsWith('.wmv'));
    };

    const renderMedia = (file, index, isLarge = false) => {
        // If the file is a video (e.g., .mp4)
        if (file.includes('.mp4')) {
            return (
                <Video key={index} loop >
                    <source src={file} type="video/mp4"  />
                    Your browser does not support the video tag.
                </Video>
            );
        }
         
        // For other files, assume it's an image
        return <ImageContainer><Image src={file} alt={`Property ${index + 1}`} key={index} /></ImageContainer>;
    };
    const currentFile = files[currentImageIndex]

    return (
        <MainContainer >
            <Container>
                <ImagesWrapper>
                    <SmallImagesContainer>
                        {files.slice(0, 3).map((file, index) => (
                            <SmallImage key={index} onClick={() => openModal(index)}>
                                {renderMedia(file, index, false)}
                            </SmallImage>
                        ))}
                        {remainingImagesCount > 0 && (
                            <SmallImage
                                overlayText={remainingImagesCount}
                                showOverlay={false}  // Set this to false for videos
                                onClick={() => openModal(3)}
                            >
                                {renderMedia(files[3], 3, true)}
                            </SmallImage>
                        )}
                    </SmallImagesContainer>
                </ImagesWrapper>

                <LargeImageContainer onClick={() => openModal(currentImageIndex)}>
                    <Arrow direction="left" onClick={(e) => { e.stopPropagation(); prevImage(); }}>
                        &#10094;
                    </Arrow>
                    <div>
                        {renderMedia(files[currentImageIndex], currentImageIndex, true)}
                    </div>
                    <Arrow direction="right" onClick={(e) => { e.stopPropagation(); nextImage(); }}>
                        &#10095;
                    </Arrow>
                </LargeImageContainer>

                <PropertyDetailsContainer>
                    {property.code && (
                        <Detail><BoldLabel>Code:</BoldLabel> {property.code}</Detail>
                    )}
                    {property.propertyName && (
                        <Detail><BoldLabel>Name:</BoldLabel> {property.propertyName}</Detail>
                    )}
                    {property.propertyType && (
                        <Detail><BoldLabel>Type:</BoldLabel> {property.propertyType}</Detail>
                    )}
                    {property.userType && (
                        <Detail><BoldLabel>User Type:</BoldLabel> {property.userType}</Detail>
                    )}
                    {property.Noofyears && (
                        <Detail><BoldLabel>No.of Years:</BoldLabel> {property.Noofyears}</Detail>
                    )}
                    {property.length && (
                        <Detail><BoldLabel>Length:</BoldLabel> {property.length}</Detail>
                    )}
                    {property.breadth && (
                        <Detail><BoldLabel>Breadth:</BoldLabel> {property.breadth}</Detail>
                    )}
                    {property.numberOfSqFeet && (
                        <Detail><BoldLabel>No.of SQ Feet:</BoldLabel> {property.numberOfSqFeet}</Detail>
                    )}
                    {property.buildUpAreaSqFt && (
                        <Detail><BoldLabel>Build Up Area(SQ Feet):</BoldLabel> {property.buildUpAreaSqFt}</Detail>
                    )}
                    {property.numberOfCent && (
                        <Detail><BoldLabel>No.of Cent:</BoldLabel> {property.numberOfCent}</Detail>
                    )}
                    {property.buildUpAreaCent && (
                        <Detail><BoldLabel>Build Up Area(Cent):</BoldLabel> {property.buildUpAreaCent}</Detail>
                    )}
                     {property.numberOfAcre && property.types !== 'Rent' &&(
                        <Detail><BoldLabel>No.of Acre:</BoldLabel> {property.numberOfAcre}</Detail>
                    )}
                     {property.buildUpAreaAcre && property.types !== 'Rent' &&(
                        <Detail><BoldLabel>Build Up Area(Acre):</BoldLabel> {property.buildUpAreaAcre}</Detail>
                    )}
                    {property.ageOfProperty && (
                        <Detail><BoldLabel>Age of Property:</BoldLabel> {property.ageOfProperty} years</Detail>
                    )}
                    {property.budget && (
                        <Detail><BoldLabel>Budget:</BoldLabel> ₹{property.budget} {property.budgetMethod}</Detail>
                    )}


                    <>
                        {property.numberOfBathrooms && (
                            <Detail><BoldLabel>Bathrooms:</BoldLabel> {property.numberOfBathrooms}</Detail>
                        )}
                        {property.numberOfRooms && (
                            <Detail><BoldLabel>Rooms:</BoldLabel> {property.numberOfRooms}</Detail>
                        )}
                        {property.facingdirection && (
                            <Detail><BoldLabel>Facing Direction:</BoldLabel> {property.facingdirection}</Detail>
                        )}
                        {property.parking && (
                            <Detail><BoldLabel>Parking:</BoldLabel> {property.parking}</Detail>
                        )}
                        {property.lift && (
                            <Detail><BoldLabel>Lift:</BoldLabel> {property.lift}</Detail>
                        )}
                        {property.electricity && (
                            <Detail><BoldLabel>Electricity:</BoldLabel> {property.electricity}</Detail>
                        )}
                        {property.waterFacility && (
                            <Detail><BoldLabel>Water Facilities:</BoldLabel> {property.waterFacility}</Detail>
                        )}
                        {property.boreWell && (
                            <Detail><BoldLabel>Bore Well:</BoldLabel> {property.boreWell}</Detail>
                        )}
                        {property.roadFacility && (
                            <Detail><BoldLabel>Road Facilities:</BoldLabel> {property.roadFacility}</Detail>
                        )}
                        {property.amenities && (
                            <Detail><BoldLabel>Amenities:</BoldLabel> {property.amenities}</Detail>
                        )}
                        {property.city && (
                            <Detail><BoldLabel>City:</BoldLabel> {property.city}</Detail>
                        )}
                    </>


                    {/* <ShowMoreButton onClick={() => setShowMore(!showMore)}>
        {showMore ? 'ShowLess' : 'ShowMore'}
    </ShowMoreButton> */}
                </PropertyDetailsContainer>
            </Container>
            <ButtonGroupWrapper>

                <ButtonGroup>
                    <Button onClick={handleShare}>
                        <FontAwesomeIcon icon={faShareAlt} style={{ marginRight: '8px' }} />
                        Share
                    </Button>
                    <>
                        <Button onClick={toggleHeart}>
                            <StyledHeart isActive={isHeartActive} style={{ marginRight: '8px', height: '16px', width: '16px' }} />
                            {isHeartActive ? 'Wishlisted' : 'Wishlist'}
                        </Button>

                        {/* Modal for login prompt */}
                        {showLoginModal && (
                            <ModalOverlay>
                                <ModalContainer>
                                    <h2>Please Log In</h2>
                                    <p>You need to be logged in to add properties to your wishlist.</p>
                                    <ModalButton onClick={closeModal1}>Close</ModalButton>
                                </ModalContainer>
                            </ModalOverlay>
                        )}
                    </>
                    {property?.createdBy === userDetails?.email ? (
                        <>
                            <Button onClick={() => handleEdit(property)}>
                                <FontAwesomeIcon icon={faEdit} style={{ marginRight: '8px' }} />
                                Edit
                            </Button>
                            <Button onClick={deleteProperty}>
                                <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '8px' }} />
                                Delete
                            </Button>
                        </>
                    ) : (
                        <Button
                            onClick={handleContactOwnerClick}
                            disabled={isDisabled} // Disable button based on isDisabled state
                            style={{
                                backgroundColor: isDisabled ? '#ccc' : '#4CAF50', // Change color when disabled
                                cursor: isDisabled ? 'not-allowed' : 'pointer' // Change cursor
                            }}
                        >
                            Contact Owner
                        </Button>
                        // <Button onClick={handleContactOwnerClick} >
                        //     <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px' }} /> Contact<span style={{ paddingLeft: '5px' }}>Owner</span>
                        // </Button>
                    )}
                </ButtonGroup>
                {isPopupOpen && (
                    <OverlayContainer>
                        <PopupContent>
                            <CloseButton onClick={togglePopup}>&times;</CloseButton>
                            <Title>Premium Subscription Required</Title>
                            <Description>
                                To access the owner's contact information, please subscribe to one of our premium plans.
                            </Description>

                            {/* Dynamically render the plan options */}
                            <PlanOptions>
                                {plans.map((plan) => (
                                    <PlanOption
                                        key={plan._id}
                                        selected={selectedPlan === plan.planName}
                                        onClick={() => handlePlanChange(plan.planName)} // Handle the click on the entire container
                                    >
                                        <input
                                            type="radio"
                                            value={plan.planName}
                                            checked={selectedPlan === plan.planName}
                                            onChange={() => handlePlanChange(plan.planName)} // This ensures the radio button stays in sync
                                        />
                                        <PlanLabel selected={selectedPlan === plan.planName}>
                                            {plan.duration} - ₹{plan.price}
                                        </PlanLabel>
                                    </PlanOption>
                                ))}
                            </PlanOptions>

                            <StyledButton onClick={handlePayment}>
                                Pay Now
                            </StyledButton>
                        </PopupContent>
                    </OverlayContainer>
                )}

                {/* {isPopupOpen && (
                    <OverlayContainer>
                        <PopupContent>
                            <CloseButton onClick={togglePopup}>&times;</CloseButton>
                            <h3>Contact Owner</h3>
                            <p>
                                <strong>Owner Name:</strong> {property.contactOwnerName || "N/A"}
                            </p>
                            <p>
                                <strong>Phone Number:</strong> {property.contactOwnerPhone || "N/A"}
                            </p>
                            <p>
                                <strong>Property Address:</strong> {property.propertyAddress || "N/A"}, {property.city} - {property.zipCode}
                            </p>
                        </PopupContent>
                    </OverlayContainer>
                )} */}
                {isPlanModalOpen && (
                    <OverlayContainer>
                        <PopupContent>
                            <CloseButton onClick={() => setIsPlanModalOpen(false)}>&times;</CloseButton>
                            <Title>Our Plans</Title>
                            <Description>{modalMessage}</Description>
                            <StyledButton onClick={() => setIsPlanModalOpen(false)}>Close</StyledButton>
                        </PopupContent>
                    </OverlayContainer>
                )}

                {isModalOpen && (
                    <FullscreenModal onClick={closeModal} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                        {/* Conditionally render image or video based on file type */}
                        {currentFile.includes('.mp4') ? (
                            <ModalVideo src={currentFile} controls autoPlay />
                        ) : (
                            <ModalImage src={currentFile} alt="Fullscreen Property View" />
                        )}
                        <ModalCloseButton onClick={closeModal}>×</ModalCloseButton>
                        <Arrow direction="left" onClick={(e) => { e.stopPropagation(); prevImage(); }}>&#10094;</Arrow>
                        <Arrow direction="right" onClick={(e) => { e.stopPropagation(); nextImage(); }}>&#10095;</Arrow>
                    </FullscreenModal>
                )}
                {isLoginModalOpen && (
                    <OverlayContainer>
                        <PopupContent>
                            <CloseButton onClick={() => setIsLoginModalOpen(false)}>&times;</CloseButton>
                            <Title>Login Required</Title>
                            <Description>
                                You need to login to contact the owner. Do you want to login now?
                            </Description>
                            <ButtonGroup>
                                <StyledButton onClick={() => navigate('/signin')}>
                                    Yes, Login
                                </StyledButton>
                                <StyledButton onClick={() => setIsLoginModalOpen(false)}>
                                    Cancel
                                </StyledButton>
                            </ButtonGroup>
                        </PopupContent>
                    </OverlayContainer>
                )}
            </ButtonGroupWrapper>
            <SimilarProperties
                propertyName={property.propertyName}
                city={property.city}
                types={property.types}
                currentId={propertyId} // Pass the current property ID
            />
        </MainContainer>

    );

};

export default IndividualProperty5;