//Edit Property Page :

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../firebaseConfig';
import Loader from '../Loader';

const EditProperty = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // State management
  const [property, setProperty] = useState(location.state?.property || {});
  const [loading, setLoading] = useState(!location.state?.property);
  const [images, setImages] = useState(property?.files || []);
  const [newImages, setNewImages] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch property data if not available in the state
  useEffect(() => {
    if (!property._id) {
      fetchProperty();
    }
  }, [id, location.state]);

  const fetchProperty = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sellProperty/${id}`);
      setProperty(response.data);
      setImages(response.data.files || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching property details', error);
      setLoading(false);
    }
  };

  // Handle image selection, ensuring uniqueness
  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Ensure new images are unique using a Set
    const uniqueNewImages = [...new Set([...newImages, ...selectedFiles])];
    setNewImages(uniqueNewImages);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProperty((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Form validation
  const validateForm = () => {
    let errors = {};

    if (!property.propertyName) errors.propertyName = 'Property Name is required';
    if (!property.propertyType) errors.propertyType = 'Property Type is required';
    // if (!property.buildUpAreaSqFt) errors.buildUpAreaSqFt = 'Buildup Area is required';
    // if (!property.numberOfSqFeet) errors.numberOfSqFeet = 'Square Feet is required';

    if (property.contactOwnerPhone && !/^(\d{10})$/.test(property.contactOwnerPhone)) {
      errors.contactOwnerPhone = 'Phone number must be exactly 10 digits';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Save property details with images
  // Save property details with images
  const handleSave = async () => {
    if (!validateForm()) return;

    try {
      const newImagesToUpload = newImages.filter(
        (image) => !property.files.some((file) => file.includes(image.name))
      );

      const cdnUrls = newImagesToUpload.length > 0
        ? await Promise.all(newImagesToUpload.map(async (image) => {
          const storageRef = ref(storage, `properties/${id}/${image.name}`);
          await uploadBytes(storageRef, image);
          return await getDownloadURL(storageRef);
        }))
        : [];

      // Combine existing files and new files to avoid duplicates
      const updatedFiles = [...new Set([...(property.files || []), ...cdnUrls])];

      const formData = {
        ...property,
        files: updatedFiles,
      };

      console.log("Form Data to be sent:", formData); // Log form data here

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/sellPropertyUpdate/${id}`,
        formData
      );

      console.log("Response from server:", response.data); // Log server response

      if (response.data.message === 'Property updated successfully') {
        setSuccessMessage('Property updated successfully!');
        navigate(`/view-property5/${id}`);
      } else {
        setErrorMessage(`Error updating property: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error updating property:', error);
      setErrorMessage('Error updating property. Please try again later.');
    }
  };

  // Delete image handler
  const handleDeleteImage = async (propertyId, imageUrl) => {
    try {
      // Call API to delete the image
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/deleteImage`, {
        params: {
          propertyId: propertyId,
          imageUrl: imageUrl,
        },
      });

      // Update state to remove the deleted image
      setImages((prevImages) => prevImages.filter((img) => img !== imageUrl));
      setProperty((prevProperty) => ({
        ...prevProperty,
        files: prevProperty.files.filter((file) => file !== imageUrl),
      }));

      console.log('Image deleted successfully');
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  // Show loader if the data is still loading
  if (loading) {
    return <div><Loader /></div>;
  }

  const removeImage = (index) => {
    // Filter out the image at the clicked index
    const updatedImages = newImages.filter((_, i) => i !== index);
    setNewImages(updatedImages);
  };

  return (
    <Background>
      <FormContainer>
        <h2>Edit Property</h2>
        {successMessage && <p>{successMessage}</p>}
        <Form>
          <Label>Property Name:</Label>
          <Input
            type="text"
            name="propertyName"
            value={property.propertyName || ''}
            onChange={handleInputChange}
            disabled
          />
          {formErrors.propertyName && <ErrorMessage>{formErrors.propertyName}</ErrorMessage>}

          <Label>Types:</Label>
          <Input
            type="text"
            name="types"
            value={property.types || ''}
            onChange={handleInputChange}
            disabled
          />

          <Label>Property Type:</Label>
          <Select name="propertyType" value={property.propertyType || ''} onChange={handleInputChange}>
            <option value="">Select Property Type</option>
            <option value="Residential">Residential</option>
            <option value="Commercial">Commercial</option>
          </Select>

          <Label>User Types:</Label>
          <Select name="userType" value={property.userType || ''} onChange={handleInputChange}>
            <option value="">Select User Type</option>
            <option value="Broker">Broker</option>
            <option value="Owner">Owner</option>
          </Select>
          {formErrors.propertyType && <ErrorMessage>{formErrors.propertyType}</ErrorMessage>}

          <Label>Images:</Label>
          <Input type="file" multiple onChange={handleImageChange} />
          {newImages.length > 0 && (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {newImages.map((image, index) => (
                <div key={index} style={{ position: 'relative', margin: '5px' }}>
                  <img
                    src={URL.createObjectURL(image)}
                    alt={image.name}
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                  <button
                    onClick={() => removeImage(index)}
                    style={{
                      position: 'absolute',
                      top: '0',
                      right: '0',
                      background: 'green',
                      color: 'white',
                      border: 'none',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      padding: '2px 6px',
                      fontSize: '12px',
                    }}
                  >
                    &times;
                  </button>
                </div>
              ))}
              <p>Images added successfully</p>
            </div>
          )}

          <Label>Uploaded Images:</Label>
          {images.length > 0 && (
            <ImageList>
              {images.map((image, index) => (
                <ImageItem key={index}>
                  {image && (
                    <>
                      {image.includes('.mp4') ? (
                        // Render video if the URL ends with .mp4 (or other video formats)
                        <video width="30%" controls>
                          <source src={image} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        // Render image for non-video URLs
                        <Image src={image} alt={`Uploaded ${index}`} />
                      )}
                      <FaTrash
                        onClick={() => {
                          console.log("Property ID:", property._id, "Image URL:", image); // Debugging log to ensure correct values
                          handleDeleteImage(property._id, image); // Call function to delete image
                        }}
                      />
                    </>
                  )}
                </ImageItem>
              ))}
            </ImageList>
          )}



          <Label>Choose Budget Method</Label>
          <Select name="budgetMethod"
            value={property.budgetMethod || ''}
            onChange={handleInputChange}>
            <option value="">Select</option>
            <option value="Overall budget">Overall budget</option>
            <option value="Per Cent">Per Cent</option>
            <option value="Per Acre">Per Acre</option>
            <option value="Monthly">Monthly</option>
          </Select>

          <Label>Budget:</Label>
          <Input
            type="text" // Keep as text to allow input as string, we'll sanitize in handleInputChange
            name="budget"
            value={property.budget || ''}
            onChange={handleInputChange}
          />
          {formErrors.length && <ErrorMessage>{formErrors.length}</ErrorMessage>}

          <Label>No.of years (only lease):</Label>
          <Input
            type="number" // Keep as text to allow input as string, we'll sanitize in handleInputChange
            name="Noofyears"
            value={property.Noofyears || ''}
            onChange={handleInputChange}
          />
          {formErrors.length && <ErrorMessage>{formErrors.length}</ErrorMessage>}


          <Label>Length:</Label>
          <Input
            type="number" // Change to number for numeric input
            name="length"
            value={property.length || ''}
            onChange={handleInputChange}
          />
          {formErrors.length && <ErrorMessage>{formErrors.length}</ErrorMessage>}

          <Label>Breadth:</Label>
          <Input
            type="number" // Change to number for numeric input
            name="breadth"
            value={property.breadth || ''}
            onChange={handleInputChange}
          />
          {formErrors.breadth && <ErrorMessage>{formErrors.breadth}</ErrorMessage>}

          <Label>Square Feet:</Label>
          <Input
            type="number" // Change to number for numeric input
            name="numberOfSqFeet"
            value={property.numberOfSqFeet || ''}
            onChange={handleInputChange}
          />
          {formErrors.sqFeet && <ErrorMessage>{formErrors.sqFeet}</ErrorMessage>}

          <Label>Buildup Area (Sq):</Label>
          <Input
            type="number" // Change to number for numeric input
            name="buildUpAreaSqFt"
            value={property.buildUpAreaSqFt || ''}
            onChange={handleInputChange}
          />
          {formErrors.buildUpAreaCent && <ErrorMessage>{formErrors.buildUpAreaSqFt}</ErrorMessage>}

          <Label>No of cent:</Label>
          <Input
            type="number" // Change to number for numeric input
            name="numberOfCent"
            value={property.numberOfCent || ''}
            onChange={handleInputChange}
          />
          {formErrors.numberOfCent && <ErrorMessage>{formErrors.numberOfCent}</ErrorMessage>}

          <Label>Buildup Area (cent):</Label>
          <Input
            type="number" // Change to number for numeric input
            name="buildUpAreaCent"
            value={property.buildUpAreaCent || ''}
            onChange={handleInputChange}
          />
          {formErrors.buildUpAreaCent && <ErrorMessage>{formErrors.buildUpAreaCent}</ErrorMessage>}


          <Label>No of Acre:</Label>
          <Input
            type="number" // Change to number for numeric input
            name="numberOfAcre"
            value={property.numberOfAcre || ''}
            onChange={handleInputChange}
          />
          

          <Label>Buildup Area (Acre):</Label>
          <Input
            type="number" // Change to number for numeric input
            name="buildUpAreaAcre"
            value={property.buildUpAreaAcre || ''}
            onChange={handleInputChange}
          />
          <Label>Electricity:</Label>
          <Select name="electricity" value={property.electricity || ''} onChange={handleInputChange}>
            <option value="">Select electricity</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </Select>
          {formErrors.electricity && <ErrorMessage>{formErrors.electricity}</ErrorMessage>}

          <Label>Water Facilities:</Label>
          <Select name="waterFacility" value={property.waterFacility || ''} onChange={handleInputChange}>
            <option value="">Select Water Facilities</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </Select>
          {formErrors.waterFacility && <ErrorMessage>{formErrors.waterFacility}</ErrorMessage>}

          <Label>Road Facilities:</Label>
          <Select name="roadFacility" value={property.roadFacility || ''} onChange={handleInputChange}>
            <option value="">Select Road Facilities</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </Select>
          {formErrors.roadFacility && <ErrorMessage>{formErrors.roadFacility}</ErrorMessage>}

          <Label>Bore Well:</Label>
          <Select name="boreWell" value={property.boreWell || ''} onChange={handleInputChange}>
            <option value="">Select Bore Well</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </Select>
          {formErrors.boreWell && <ErrorMessage>{formErrors.boreWell}</ErrorMessage>}

          <Label>Age of Property:</Label>
          <Input
            type="number" // Change to number for numeric input
            name="ageOfProperty"
            value={property.ageOfProperty || ''}
            onChange={handleInputChange}
          />

          <Label>No of  Rooms:</Label>
          <Select name="numberOfRooms"
            value={property.numberOfRooms || ''}
            onChange={handleInputChange}>
            <option value="">Select</option>
            <option value="1 BHK">1 BHK</option>
            <option value="2 BHK">2 BHK</option>
            <option value="3+ BHK">3+ BHK</option>
            <option value="Single Room">Single Room</option>
          </Select>

          <Label>No. Bathrooms:</Label>
          <Input
            type="number" // Change to number for numeric input
            name="numberOfBathrooms"
            value={property.numberOfBathrooms || ''}
            onChange={handleInputChange}
          />

          <Label>Parking:</Label>
          <Select name="parking" value={property.parking || ''} onChange={handleInputChange}>
            <option value="">Select Parking</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </Select>

          <Label>Lift:</Label>
          <Select name="lift" value={property.lift || ''} onChange={handleInputChange}>
            <option value="">Select Lift</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </Select>

          <Label>Facing Direction:</Label>
          <Input
            type="text"
            name="facingdirection"
            value={property.facingdirection || ''}
            onChange={handleInputChange}
          />
          

          
          <Label>Amenities:</Label>
          <Input
            type="text"
            name="amenities"
            value={property.amenities || ''}
            onChange={handleInputChange}
          />
          {formErrors.boreWell && <ErrorMessage>{formErrors.boreWell}</ErrorMessage>}

          <Label>Contact Owner Name:</Label>
          <Input
            type="text"
            name="contactOwnerName"
            value={property.contactOwnerName || ''}
            onChange={handleInputChange}
          />

          <Label>Property Address:</Label>
          <Input
            type="text"
            name="propertyAddress"
            value={property.propertyAddress || ''}
            onChange={handleInputChange}
          />

          <Label>City:</Label>
          <Dropdown
            name="city"
            value={property.city || ""}
            onChange={handleInputChange}
          >
            <option value="">Select City</option>
            <option value="Ariyalur">Ariyalur</option>
            <option value="Chengalpattu">Chengalpattu</option>
            <option value="Chennai">Chennai</option>
            <option value="Coimbatore">Coimbatore</option>
            <option value="Cuddalore">Cuddalore</option>
            <option value="Dharmapuri">Dharmapuri</option>
            <option value="Dindigul">Dindigul</option>
            <option value="Erode">Erode</option>
            <option value="Kallakurichi">Kallakurichi</option>
            <option value="Kancheepuram">Kancheepuram</option>
            <option value="Karur">Karur</option>
            <option value="Krishnagiri">Krishnagiri</option>
            <option value="Madurai">Madurai</option>
            <option value="Mayiladuthurai">Mayiladuthurai</option>
            <option value="Nagapattinam">Nagapattinam</option>
            <option value="Nagercoil">Nagercoil (Kanniyakumari)</option>
            <option value="Namakkal">Namakkal</option>
            <option value="Perambalur">Perambalur</option>
            <option value="Pudukottai">Pudukottai</option>
            <option value="Ramanathapuram">Ramanathapuram</option>
            <option value="Ranipet">Ranipet</option>
            <option value="Salem">Salem</option>
            <option value="Sivagangai">Sivagangai</option>
            <option value="Tenkasi">Tenkasi</option>
            <option value="Thanjavur">Thanjavur</option>
            <option value="Theni">Theni</option>
            <option value="Thiruvallur">Thiruvallur</option>
            <option value="Thiruvarur">Thiruvarur</option>
            <option value="Thoothukudi">Thoothukudi</option>
            <option value="Trichirappalli">Trichirappalli (Trichy)</option>
            <option value="Thirunelveli">Thirunelveli</option>
            <option value="Tirupathur">Tirupathur</option>
            <option value="Tiruppur">Tiruppur</option>
            <option value="Tiruvannamalai">Tiruvannamalai</option>
            <option value="The Nilgiris">The Nilgiris</option>
            <option value="Vellore">Vellore</option>
            <option value="Viluppuram">Viluppuram</option>
            <option value="Virudhunagar">Virudhunagar</option>
          </Dropdown>

          <Label>Contact Owner Phone Number:</Label>
          <Input
            type="number"
            name="contactOwnerPhone"
            value={property.contactOwnerPhone || ''}
            onChange={handleInputChange}
          />
          {formErrors.contactOwnerPhoneNumber && <ErrorMessage>{formErrors.contactOwnerPhone}</ErrorMessage>}


          <Label>Zip Code:</Label>
          <Input
            type="number" // Change to number for numeric input
            name="zipcode"
            value={property.zipCode || ''}
            onChange={handleInputChange}
          />



        </Form>
        <ButtonContainer>
          <Button onClick={handleSave}>Save</Button>
        </ButtonContainer>
      </FormContainer>
    </Background>
  );
};

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  padding: 20px;
`;

const FormContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 8px;
  font-weight: bold;
  font-family: 'lora';
`;

const Input = styled.input`
  margin-bottom: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const ImagePreview = styled.div`
  margin: 10px 0;
  color: green;
`;

const ImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ImageItem = styled.div`
  position: relative;
  margin-right: 10px;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;  /* Center the button */
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 12px 24px;
  background-color: ${(props) => props.theme.colors.medium};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 150px;  /* Increased width */
  font-size: 16px;
   &:hover {
    background-color: ${(props) => props.theme.colors.dark};
    transform: translateY(-2px);
  }
`;

const Select = styled.select`
  margin-bottom: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Dropdown = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f2f8fc;
  border-radius: 5px;
  font-size: 16px;

  @media (max-width: 480px) {
    font-size: 14px; /* Reduce font size on mobile */
  }
`;

export default EditProperty;
