// Loader.js
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Define the spin animation
const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

// Styled components for the loader
const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
`;

const Spinner = styled.div`
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px; /* Size of the spinner */
    height: 50px; /* Size of the spinner */
    animation: ${spin} 1s linear infinite; /* Spinner animation */
`;

// Loader component
const Loader = () => {
    return (
        <LoaderContainer>
            <Spinner />
        </LoaderContainer>
    );
};

export default Loader;
