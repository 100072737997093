import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
const SellProperty = () => {
  const [sell, setSell] = useState([]);
  const [loading, setLoading] = useState(true);
  const sellProperty = async () => {
    try {
      const response = await axios.get('http://localhost:3007/sellProperty');
      if (response.data && Array.isArray(response.data)) {
        setSell(response.data);
      } else {
        console.error("API response is not an array");
        setSell([]);
      }
    } catch (error) {
      console.error("Error fetching sell property data", error);
      setSell([]);
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`http://localhost:3007/sellPropertyDelete?id=${id}`);
      if (response.data.message === 'Property deleted successfully') {
        setSell(sell.filter(property => property._id !== id)); // Filter out the deleted property
      } else {
        console.error("Error deleting property:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting property", error);
    }
  };
  useEffect(() => {
    sellProperty();
  }, []);
  if (loading) {
    return <LoadingMessage>Loading properties...</LoadingMessage>;
  }
  return (
    <Container>
      <Title>Sell Property</Title>
      <Content>
        {sell.length > 0 ? (
          sell.map((property) => (
            <PropertyCard key={property._id}>
              <ImageContainer>
                {property.uploadImage && property.uploadImage.map((image, index) => (
                  <Image key={index} src={image} alt={property.propertyName} />
                ))}
              </ImageContainer>
              <Details>
                <PropertyName>{property.propertyName}</PropertyName>
                <Description><BoldText>Property Type:</BoldText> {property.propertyType} || <BoldText>Buildup Area:</BoldText> {property.buildArea} || <BoldText>Sq Feet:</BoldText> {property.sqFeet} || <BoldText>Age of Property:</BoldText> {property.ageProperty}</Description>
                <Location><BoldText>No. Rooms:</BoldText> {property.noRoom} || <BoldText>No. Bathrooms:</BoldText> {property.noBathroom} || <BoldText>Parking:</BoldText> {property.parking} || <BoldText>Lift:</BoldText> {property.lift}</Location>
                <Location><BoldText>Types:</BoldText> {property.types} || <BoldText>Contact Owner Name:</BoldText> {property.contactOwnerName} || <BoldText>Contact Owner PhoneNumber:</BoldText> {property.contactOwnerPhoneNumber}</Location>
                <Location><BoldText>Property Address:</BoldText> {property.propertyAddress} || <BoldText>City:</BoldText> {property.city} || <BoldText>Zip Code:</BoldText> {property.zipCode} || <BoldText>State:</BoldText> {property.state}</Location>
                <ViewButton>Edit</ViewButton>
                <ViewButton onClick={() => handleDelete(property._id)}>Delete</ViewButton>
              </Details>
            </PropertyCard>
          ))
        ) : (
          <NoPropertiesMessage>No properties available to display.</NoPropertiesMessage>
        )}
      </Content>
    </Container>
  );
};
export default SellProperty;
const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.light};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
`;
const Title = styled.h2`
  margin: 0 0 20px;
  font-size: 2rem;
  text-align: center;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
const PropertyCard = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 95%;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  overflow-x: auto;
  max-width: 250px;
  margin-right: 20px;
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
  }
  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;
const Image = styled.img`
  width: 200px;
  height: 150px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;
const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left; /* Center align buttons */
  width: 100%; /* Ensure it spans the width of the card */
`;
const PropertyName = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
`;
const Description = styled.p`
  margin: 5px 0;
`;
const Location = styled.p`
  margin: 5px 0;
`;
const BoldText = styled.span`
  font-weight: bold;
`;
const ViewButton = styled.button`
  margin-top: 10px;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.light};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px; /* Reduce button width */
  text-align: center; /* Center text inside button */
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;
const LoadingMessage = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.dark};
`;
const NoPropertiesMessage = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.dark};
`;

