// PrivacyPolicy.js
import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  min-height: 100vh; /* Ensure container takes at least full viewport height */
  margin: 0;
  padding: 20px; /* Add padding for better readability */
  background-color: ${({ theme }) => theme.colors.light};
  color: ${({ theme }) => theme.colors.dark};
  line-height: 1.6;
  box-sizing: border-box; /* Ensure padding is included in height calculation */
`;
const Section = styled.section`
  margin-bottom: 20px;
  width: 100%; /* Ensure sections take full width */
  max-width: 800px; /* Optional: limit maximum width for better readability */
`;
const Title = styled.h1`
  font-size: 2rem;
  font-family: 'lora';
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.medium};
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;
const SubTitle = styled.h2`
  font-size: 1.5rem;
  font-family: 'lora';
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.medium};
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
const Paragraph = styled.p`
  font-size: 1rem;
  font-family: 'lora';
  color: #000000;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;
const PrivacyPolicy = () => {
  return (
    <Container>
      <Title>Privacy Policy</Title>
      <Section>
        <SubTitle>Introduction</SubTitle>
        <Paragraph>
          This Privacy Policy explains how we collect, use, and protect your personal information when you use our Service. <br />
          By using the Service, you agree to our data practices as outlined here.
        </Paragraph>
      </Section>
      <Section>
        <SubTitle>Definitions</SubTitle>
        <Paragraph><strong>Account:</strong> Your unique access to the Service.</Paragraph>
        <Paragraph><strong>Company:</strong> Akshayalands, located at 17/4, Town Extension, 2nd Street, Cotton Market Post Tiruppur 641604, Tamil Nadu, India.</Paragraph>
        <Paragraph><strong>Cookies:</strong> Small files stored on your device to track your activity.</Paragraph>
        <Paragraph><strong>Personal Data:</strong> Information that identifies you.</Paragraph>
      </Section>
      <Section>
        <SubTitle>Data Collection and Use</SubTitle>
        <Paragraph><strong>Types of Data:</strong> Includes email, name, phone number, and usage data.</Paragraph>
        <Paragraph><strong>Usage Data:</strong> Automatically collected data like IP address and browser type.</Paragraph>
        <Paragraph><strong>Cookies:</strong> Used to enhance service functionality and track usage.</Paragraph>
      </Section>
      <Section>
        <SubTitle>Uses of Data</SubTitle>
        <Paragraph>To provide and improve the Service.</Paragraph>
        <Paragraph>To manage your Account and communicate with you.</Paragraph>
        <Paragraph>For business transfers and legal obligations.</Paragraph>
      </Section>
      <Section>
        <SubTitle>Data Sharing</SubTitle>
        <Paragraph>With Service Providers, affiliates, and business partners.</Paragraph>
        <Paragraph>In business transfers or legal situations.</Paragraph>
      </Section>
      <Section>
        <SubTitle>Data Retention</SubTitle>
        <Paragraph>Your data is retained as long as necessary for its purposes and legal obligations.</Paragraph>
      </Section>
      <Section>
        <SubTitle>Data Transfer</SubTitle>
        <Paragraph>Your data may be transferred to and stored in locations outside your jurisdiction. We ensure adequate protections are in place.</Paragraph>
      </Section>
      <Section>
        <SubTitle>Deleting Your Data</SubTitle>
        <Paragraph>You can request deletion of your data through account settings or by contacting us.</Paragraph>
      </Section>
      <Section>
        <SubTitle>Security</SubTitle>
        <Paragraph>We use measures to protect your data but cannot guarantee complete security.</Paragraph>
      </Section>
      <Section>
        <SubTitle>Children’s Privacy</SubTitle>
        <Paragraph>We do not collect data from individuals under 13. Contact us if you believe we have collected such data.</Paragraph>
      </Section>
      <Section>
        <SubTitle>External Links</SubTitle>
        <Paragraph>We are not responsible for the privacy practices of other websites linked from our Service.</Paragraph>
      </Section>
      <Section>
        <SubTitle>Policy Updates</SubTitle>
        <Paragraph>We may update this policy and will notify you of any changes.</Paragraph>
      </Section>
    </Container>
  );
};
export default PrivacyPolicy;






