import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import { AiOutlineTwitter } from 'react-icons/ai';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterLinks>
          <FooterColumn>
            <FooterLink href="/about">About Us</FooterLink>
            <FooterLink href="/contact">Contact Us</FooterLink>
            <Copyright>© Akshayalands 2024</Copyright>
          </FooterColumn>
          <FooterColumn>
            <FooterLink href="/termsandconditions">Terms & Conditions</FooterLink>
            <FooterLink href="/privacypolicy">Privacy Policy</FooterLink>
            <FooterLink href="/refund">Refund & Cancellation</FooterLink>
          </FooterColumn>
        </FooterLinks>
        <SocialSection>
          <SocialIcons>
            <a href="https://www.facebook.com/profile.php?id=61561717779027" target="_blank" rel="noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/akashaya_lands/?igsh=MWt3b3k3MWlheTBwcQ%3D%3D" target="_blank" rel="noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.youtube.com/@akshayalands" target="_blank" rel="noreferrer">
              <i className="fab fa-youtube"></i>
            </a>
            <a href="https://wa.me/+919843353364" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-whatsapp"></i>
            </a>
          </SocialIcons>
          <ConnectButton>
            <StyledLink href="mailto:akshayalands.tup@gmail.com">Connect with us</StyledLink>
          </ConnectButton>
        </SocialSection>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;

// Styled Components
const FooterContainer = styled.footer`
  background: linear-gradient(135deg, #2E3A59, #3B5066, #434F81, #7C5DB2);
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 50px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterLink = styled.a`
  color: ${({ theme }) => theme.colors.light};
  margin-bottom: 10px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const SocialSection = styled.div`
  display: grid;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 15px;
  a {
    color: ${({ theme }) => theme.colors.light};
    font-size: 24px;
    transition: color 0.3s;
    &:hover {
      color: ${({ theme }) => theme.colors.medium};
    }
  }
`;

const ConnectButton = styled.button`
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.light};
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.div`
  color: ${({ theme }) => theme.colors.light};
  margin-top: 10px;
  font-size: 14px;
`;
