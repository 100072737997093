import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthProvider';
import { FaUserCircle, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa'; // Import profile icon
import ItemSection from './ItemSection';
import axios from 'axios';
const ProfileContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ProfileHeader = styled.div`
  width: 100%;
  position: relative;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.light};
`;
const BackgroundImage = styled.div`
  width: 100%;
  height: 250px;
  background-image: url('https://cdn.shopify.com/s/files/1/0851/4607/5431/files/Untitled_design_20.png?v=1725961182');
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay for better text visibility */
  }
`;
const ProfileIcon = styled(FaUserCircle)`
  font-size: 120px;
  color: ${({ theme }) => theme.colors.light};
  position: absolute;
  top: 50%;
  transform: translateY(-50%); /* Vertically center the icon */
  z-index: 2; /* Ensure the icon stays above the overlay */
`;
const ProfileInfo = styled.div`
  text-align: left;
  margin-top: 20px;
  z-index: 2;
`;
const Name = styled.h2`
  margin: 10px 0;
`;
const Location = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.dark};
`;
const ContactInfo = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: #000000;
  font-family: 'Poppins';
  font-size: 16px;
`;
const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 500px;
  background-color: ${({ theme }) => theme.colors.extralight};
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: -40px;
`;
const StatItem = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.medium};
`;
const StatNumber = styled.h3`
  margin: 0;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.medium};
`;
const StatLabel = styled.p`
  margin: 0;
  font-size: 14px;
  color: #777;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px; /* Space between buttons */
  margin-top: 20px;
  margin-bottom: 10px;
`;
const EditButton = styled.button`
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.light};
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;
const LogoutButton = styled.button`
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.light};
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;
const Address = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.dark};
`;
const Profile = () => {
  const [user, setUser] = useState(null);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  // Load user details from localStorage
// Load user details from localStorage
useEffect(() => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  console.log(userDetails)
  if (userDetails) {
    setUser(userDetails);
  }
}, []);
// Fetch profile data based on user email
const fetchProfile = async () => {
  if (user && user.email) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile?email=${user.email}`);
      setProfileData(response.data); // Store full profile data
      localStorage.setItem('premiumPlan', response.data.premiumPlan);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  }
};
useEffect(() => {
  fetchProfile();
}, [user]);
  const handleLogout = () => {
    logout();
    localStorage.removeItem('wishlist');
    localStorage.removeItem('premiumPlan'); 
    navigate('/signin');
  };
  const isAdmin = user && user.email === 'akshayalands.tup@gmail.com';
  if (!user) {
    return <ProfileContainer>Loading user...</ProfileContainer>;
  }
  return (
<ProfileContainer>
      <ProfileHeader>
        <BackgroundImage>
          <ProfileIcon /> {/* Centered profile icon */}
        </BackgroundImage>
        <ProfileInfo>
          {/* <Name>{profileData?.firstName} {profileData?.lastName}</Name> */}
          <ContactInfo><FaEnvelope /> <strong>Email:</strong> {profileData?.email}</ContactInfo>
          <ContactInfo><FaPhone /> <strong>Phone:</strong> {profileData?.phoneNumber}</ContactInfo>
          {/* <ContactInfo><FaMapMarkerAlt /> <strong>Address:</strong> {profileData?.address || 'No address provided'}</ContactInfo> */}
          {/* <ContactInfo><strong>Premium Plan:</strong> {profileData?.premiumPlan}</ContactInfo>
          {profileData?.planDetails ? (
            <div>
              <strong>Plan Details:</strong>
              <ul>
                <li>Plan Name: {profileData.planDetails.planName}</li>
                <li>Price: {profileData.planDetails.price}</li>
                <li>Expiration Date: {profileData.planDetails.expirationDate}</li>
              </ul>
            </div>
          ) : (
            <div>No plan details available</div>
          )}  */}

     <ContactInfo><strong>Premium Plan:</strong> {profileData?.premiumPlan}</ContactInfo>
      
       <ContactInfo>{profileData?.planDetails ? (
            <div>
              <strong>Plan Details:</strong>
              <ul>
                <li>Plan Name: {profileData.planDetails.planName}</li>
                <li>Price: {profileData.planDetails.price}</li>
                <li>
        Expiration Date:{" "}
        {new Intl.DateTimeFormat('en-IN', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          // hour: '2-digit',
          // minute: '2-digit',
          // second: '2-digit',
          // timeZone: 'Asia/Kolkata' 
        }).format(new Date(profileData.planDetails.expirationDate))}
      </li>
              </ul>
            </div>
          ) : (
            <div>No plan details available</div>
          )}</ContactInfo>
        </ProfileInfo>

      </ProfileHeader>
       
      <ItemSection />
      
      <ButtonContainer>
        {isAdmin && (
          <span onClick={() => navigate('/adminhome')}>
            <EditButton>Dashboard</EditButton>
          </span>
        )}
        <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
      </ButtonContainer>
    </ProfileContainer>
  );
};
export default Profile;