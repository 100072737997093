import React, { createContext, useContext, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithPhoneNumber,
  RecaptchaVerifier
} from 'firebase/auth';
import { auth } from './firebaseConfig'; // Import Firebase config
// Create the AuthContext
const AuthContext = createContext();
// Create the hook for using auth
export const useAuth = () => {
  return useContext(AuthContext);
};
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    JSON.parse(localStorage.getItem('isAuthenticated')) || false
  );
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem('userDetails')) || null
  );
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [otpSent, setOtpSent] = useState(false); // Track if OTP was sent
  const [otpVerificationLoading, setOtpVerificationLoading] = useState(false); // Loading state for OTP verification
  // Google Sign-In function
  const signinWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const firebaseUser = userCredential.user;
      // Update state and local storage
      setIsAuthenticated(true);
      setUserDetails(firebaseUser);
      localStorage.setItem('isAuthenticated', JSON.stringify(true));
      localStorage.setItem('userDetails', JSON.stringify(firebaseUser));
      return firebaseUser;
    } catch (error) {
      console.error('Error during Google sign-in:', error);
      throw error; // Handle error in the UI
    }
  };
  // Signup function
  const signup = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const firebaseUser = userCredential.user;
      // Log the Firebase UID to the console
      console.log("Signed up Firebase UID:", firebaseUser.uid); // Log UID
      // Send a verification email
      await sendEmailVerification(firebaseUser);
      setVerificationEmailSent(true);
      alert('Verification email sent. Please check your inbox.');
      // Log the user out after sending the verification email
      await signOut(auth);
      // Return the firebaseUser object to the caller
      return firebaseUser;
    } catch (error) {
      console.error('Error during signup:', error);
      throw error;
    }
  };
  // Signin function
  const signin = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const firebaseUser = userCredential.user;
      // Reload the user to get the latest email verification status
      await firebaseUser.reload(); // Ensure you have the latest user data
      if (firebaseUser.emailVerified) {
        // Login logic after email is verified
        setIsAuthenticated(true);
        setUserDetails(firebaseUser);
        localStorage.setItem('isAuthenticated', JSON.stringify(true));
        localStorage.setItem('userDetails', JSON.stringify(firebaseUser));
      } else {
        // Email not verified, alert user
        alert('Please verify your email before logging in.');
      }
    } catch (error) {
      console.error('Error during signin:', error);
      throw error; // Handle error in the UI
    }
  };
  // OTP Sign-in function
  const signinWithPhoneOTP = async (phoneNumber) => {
    const auth = getAuth(); // Get the Firebase auth instance
    setOtpVerificationLoading(true); // Set loading state
    try {
      // Set up reCAPTCHA
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      }, auth);
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
      setOtpSent(true); // Mark OTP as sent
      window.confirmationResult = confirmationResult; // Store confirmationResult globally
      alert('OTP sent to your phone number.');
    } catch (error) {
      console.error('Error during phone sign-in:', error);
      throw error; // Handle error in the UI
    } finally {
      setOtpVerificationLoading(false); // Reset loading state
    }
  };
  // Verify OTP function
  const verifyOtp = async (otp) => {
    setOtpVerificationLoading(true); // Set loading state
    try {
      const confirmationResult = window.confirmationResult;
      await confirmationResult.confirm(otp); // Confirm the OTP
      setIsAuthenticated(true); // Mark user as authenticated
      alert('Phone number verified successfully!');
    } catch (error) {
      console.error('Error verifying OTP:', error);
      throw error; // Handle error in the UI
    } finally {
      setOtpVerificationLoading(false); // Reset loading state
    }
  };
  // Logout function
  const logout = async () => {
    try {
      await signOut(auth);
      setIsAuthenticated(false);
      setUserDetails(null);
      localStorage.setItem('isAuthenticated', JSON.stringify(false));
      localStorage.removeItem('userDetails');
    } catch (error) {
      console.error('Error during logout:', error);
      throw error;
    }
  };
  // Reset password function
  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error during password reset:', error);
      throw error; // Handle error in the UI
    }
  };
  const value = {
    isAuthenticated,
    userDetails,
    verificationEmailSent,
    otpSent,
    otpVerificationLoading,
    signup,
    signin,
    signinWithGoogle, // Add Google sign-in to context value
    signinWithPhoneOTP, // Add phone sign-in to context value
    verifyOtp, // Add OTP verification to context value
    logout,
    resetPassword, // Add resetPassword to context value
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};









