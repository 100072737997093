import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaTrashAlt } from 'react-icons/fa';
import axios from 'axios';
const Container = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.light};
  color: ${({ theme }) => theme.colors.dark};
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;
const TableHeader = styled.th`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.light};
  text-align: left;
`;
const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #F2F2F2;
  }
`;
const TableData = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;
const DeleteIcon = styled(FaTrashAlt)`
  color: red;
  cursor: pointer;
`;
const UserDetails = () => {
  const [users, setUsers] = useState([]);
  // Fetch users from the backend
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`http://localhost:3007/users`);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);
  // Delete user function
  const deleteUser = async (userId) => {
    const confirmed = window.confirm("Are you sure you want to delete this user?");
    if (!confirmed) return;
  
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/deleteUser/${userId}`);
      if (response.status === 200) {
        // Remove user from the state after successful deletion
        setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
      } else {
        console.error('Failed to delete user:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Error deleting user, please try again.');
    }
  };
  
  return (
    <Container>
      <h2>User Details</h2>
      <Table>
        <thead>
          <tr>
            <TableHeader>First Name</TableHeader>
            <TableHeader>Last Name</TableHeader>
            <TableHeader>Email</TableHeader>
            <TableHeader>Phone Number</TableHeader>
            <TableHeader>City</TableHeader>         
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <TableRow key={user._id}>
              <TableData>{user.firstName}</TableData>
              <TableData>{user.lastName}</TableData>
              <TableData>{user.email}</TableData>
              <TableData>{user.phoneNumber}</TableData>
              <TableData>{user.city}</TableData>
              {/* <TableData>
                <DeleteIcon onClick={() => deleteUser(user._id)} />
              </TableData> */}
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};
export default UserDetails;






