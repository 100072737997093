import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../AuthProvider';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '../../useMediaQuery'; // Adjust the path if necessary
import Loader from '../Loader';
// Define styled components
const SectionContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.medium};
  border-radius: 8px;
  padding: 10px;
  margin-top: -40px; /* Adjust as needed */
`;
const TabContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
`;
const TabButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.extralight};
  color: ${({ theme, active }) => (active ? theme.colors.medium : theme.colors.dark)};
  border-bottom: ${({ active, theme }) => (active ? `2px solid ${theme.colors.medium}` : 'none')};
  outline: none;
`;
const WishlistHighlight = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  background-color: ${({ theme }) => theme.colors.dark};
  height: 30px;
  width: 30px;
  clip-path: polygon(50% 0, 100% 100%, 0% 100%);
  z-index: -1;
`;
const CardContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
`;
const Card = styled.div`
  width: 150px;
  border: 1px solid ${({ theme }) => theme.colors.medium};
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  /* Responsive styles */
  @media (max-width: 768px) {
    width: 100px; /* Adjust width for smaller screens */
  }
  @media (max-width: 480px) {
    width: 80%; /* Full width for very small screens */
  }
`;
const CardImage = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
`;
const CardTitle = styled.h4`
  margin: 10px 0 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.dark};
`;
const CardDescription = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.medium};
`;
const ViewButton = styled.button`
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.light};
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;
const MediaContainer = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
`;
// const CardImage = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   border-radius: 4px;
// `;
const CardVideo = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;
// Define the custom messages
const noPropertiesMessages = {
  Sell: 'No Properties Available',
  Wishlist: 'No Properties were Wishlisted'
};
const ItemSection = () => {
  const [activeTab, setActiveTab] = useState('Sell');
  const { userDetails } = useAuth();
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  // Use media query to determine screen size
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  // Fetch wishlist from local storage
  const fetchWishlistFromLocalStorage = () => {
    const storedWishlist = localStorage.getItem('wishlist');
    return storedWishlist ? JSON.parse(storedWishlist) : [];
  };
  // Fetch wishlist from the server
  const fetchWishlistFromServer = async (email) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/wishlist?email=${email}`);
      if (!response.ok) {
        throw new Error('Failed to fetch wishlist');
      }
      const data = await response.json();
      return data.properties || [];
    } catch (error) {
      console.error('Error fetching wishlist:', error);
      return [];
    }
  };

  const isVideoFile = (file) => {
    const videoExtensions = ['mp4', 'mov', 'avi', 'wmv'];
    return videoExtensions.some(extension => file.toLowerCase().includes('.mp4'));
  };

  const handleImageError = (event) => {
    if (event.target.tagName === 'IMG') {
      event.target.src = 'https://via.placeholder.com/150'; // Placeholder image
    }
  };

  useEffect(() => {
    const fetchProperties = async () => {
      if (userDetails) {
        try {
          let propertiesData = [];
          if (activeTab === 'Sell') {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-properties`, {
              params: { createdBy: userDetails.email }
            });
            propertiesData = response.data;
          } else if (activeTab === 'Wishlist') {
            const localWishlist = fetchWishlistFromLocalStorage();
            const serverWishlist = await fetchWishlistFromServer(userDetails.email);
            // Merge local storage wishlist with server data
            propertiesData = [
              ...localWishlist.filter(item => !serverWishlist.some(fetchedItem => fetchedItem._id === item._id)),
              ...serverWishlist
            ];
          }
          setProperties(propertiesData);
        } catch (error) {
          console.error('Error fetching properties:', error);
          setError('Failed to fetch properties');
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    fetchProperties();
  }, [activeTab, userDetails]);
  if (loading) return <Loader/>;
  if (error) return <p>{error}</p>;
  // Navigate to the property detail page when clicking the "View" button
  const handleViewClick = (propertyId) => {
    navigate(`/view-property5/${propertyId}`);
  };
  return (
    <SectionContainer>
      <TabContainer>
        <TabButton active={activeTab === 'Sell'} onClick={() => setActiveTab('Sell')}>
          Sell
        </TabButton>
        <TabButton active={activeTab === 'Wishlist'} onClick={() => setActiveTab('Wishlist')}>
          Wishlist
        </TabButton>
        {activeTab === 'Wishlist' && <WishlistHighlight />}
      </TabContainer>
      <CardContainer>
      {properties.length > 0 ? (
          properties.map((property) => (
            property && property.files && property.files.length > 0 ? (
              <Card key={property._id}>
                <MediaContainer>
                  {isVideoFile(property.files[0]) ? (
                    <CardVideo >
                      <source src={property.files[0]} type="video/mp4" />
                      Your browser does not support the video tag.
                    </CardVideo>
                  ) : (
                    <CardImage
                      src={property.files[0]}
                      alt={property.propertyName}
                      onError={handleImageError}
                    />
                  )}
                </MediaContainer>
                <CardTitle>{property.propertyName}</CardTitle>
                <CardDescription>{property.propertyType}</CardDescription>
                <CardDescription>{property.status}</CardDescription>
                <ViewButton onClick={() => handleViewClick(property._id)}>View</ViewButton>
              </Card>
            ) : (
              <p key={property ? property._id : 'placeholder'}>     </p>
            )
          ))
        ) : (
          <p>{noPropertiesMessages[activeTab]}</p>
        )}
      </CardContainer>
    </SectionContainer>
  );
};
export default ItemSection;














