//Sign up :

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthProvider';


 // Import useAuth hook from AuthProvider
const SignupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('https://cdn.shopify.com/s/files/1/0851/4607/5431/files/Untitled_design_20.png?v=1725961182');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const SignupBox = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 350px;
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0.5);
  color: white;
`;
const Title = styled.h2`
  text-align: center;
  margin-bottom: 30px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.extralight};
`;
const EyeIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${({ theme }) => theme.colors.medium};
`;
const Button = styled.button`
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.light};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;
const Message = styled.div`
  color: ${({ isSuccess }) => (isSuccess ? 'green' : 'red')};
  margin-bottom: 15px;
  font-weight: bold;
`;

const Select = styled.select`
padding: 12px; /* Match Signin.js padding */
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%; /* Ensure input fields take full width like in Signin.js */
  background-color: ${({ theme }) => theme.colors.extralight};

`
const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    city: '',
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  
  
  const navigate = useNavigate();
  const [districts, setDistricts] = useState([]);
  // const { signup } = useAuth(); // Access the signup function from AuthProvider
  const { signup, signinWithGoogle } = useAuth(); // Access the signup function from AuthProvider

  const tamilNaduDistricts = [
    'Ariyalur', 'Chennai', 'Coimbatore', 'Cuddalore', 'Dharmapuri',
    'Dindigul', 'Erode', 'Kanchipuram', 'Kanyakumari', 'Karur',
    'Krishnagiri', 'Madurai', 'Nagapattinam', 'Namakkal', 'Perambalur',
    'Pudukkottai', 'Ramanathapuram', 'Salem', 'Sivaganga', 'Thanjavur',
    'Theni', 'Tiruchirappalli', 'Tirunelveli', 'Tirupattur', 'Tiruvallur', 'Tiruppur',
    'Tiruvannamalai', 'Vellore', 'Virudhunagar', 'Kodaikanal', 'Kanyakumari',
  ];

  useEffect(() => {
    // Load the districts when the component mounts
    setDistricts(tamilNaduDistricts);
  }, []);

  const handleGoogleSignIn = async (e) => {
    e.preventDefault(); // Prevent default form submission
    try {
      // Step 1: Sign in with Google and get user details
      const user = await signinWithGoogle();
      console.log('User signed in with Google:', user);
  
      // Step 2: Send user details to your backend
      const response = await fetch(`${process.env.REACT_APP_API_URL}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: user.displayName.split(' ')[0],  // Extract first name
          lastName: user.displayName.split(' ')[1],   // Extract last name
          email: user.email,                          // User's email
          uid: user.uid,                              // Firebase UID
          // Optionally, you can add phoneNumber or address if available
        }),
      });
  
      // Step 3: Handle backend response
      if (response.ok) {
        const data = await response.json();
        console.log('Google Sign-In successful, saved to DB:', data);
  
        // Step 4: Display success message and redirect
        setSuccessMessage('Successfully signed in with Google!');
        setTimeout(() => navigate('/'), 3000); // Redirect after 3 seconds
      } else {
        throw new Error('Failed to save user data to the backend');
      }
    } catch (error) {
      console.error('Error during Google sign-in or saving data:', error);
      setError('Google sign-in failed. Please try again.');
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError('');
    setSuccessMessage('');
  };

  const validateForm = () => {
    const { phoneNumber, password } = formData;
    if (phoneNumber.length !== 10) {
      return 'Phone number must be exactly 10 digits.';
    }
    // const passwordRegex = "";
    // if (!passwordRegex.test(password)) {
    //   return 'Password must be at least 8 characters long, and include one uppercase, one lowercase, one number, and one special character.';
    // }
    return ''; // No errors
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }
    try {
      // Call the signup function from AuthProvider to get Firebase UID
      const firebaseUser = await signup(formData.email, formData.password);
      console.log("Firebase UID:", firebaseUser.uid);

      // Send user data along with Firebase UID and password to your backend
      const response = await fetch('http://localhost:3007/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: firebaseUser.uid, // Include Firebase UID
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password, // Send password to the server
          phoneNumber: formData.phoneNumber,
          city: formData.city,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to sign up user');
      }
      
      setSuccessMessage('Signup successful! Check your email for verification.');
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phoneNumber: '',
        city: '',
      });
      setTimeout(() => navigate('/signin'), 1000);
    } catch (error) {
      // setError(`Signup failed: ${error.message}`);
      console.error(`Signup failed: ${error.message}`)
    }
  };



  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <SignupContainer>
      <SignupBox>
        <Title>Sign Up</Title>
        {error && <Message>{error}</Message>}
        {successMessage && <Message isSuccess>{successMessage}</Message>}
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name"
            required
          />
          <Input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            required
          />
          <Input
            type="email"  
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
          <InputWrapper>
            <Input
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              required
            />
            <EyeIcon onClick={togglePasswordVisibility}>
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </EyeIcon>
          </InputWrapper>
          <Input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={(e) => {
              const phoneNumber = e.target.value;
              // Allow only numbers and make sure length doesn't exceed 10 digits
              if (/^\d{0,10}$/.test(phoneNumber)) {
                setFormData({ ...formData, phoneNumber });
              }
            }}
            placeholder="Phone Number"
            required
            maxLength="10"
            pattern="\d{10}"
            title="Phone number must be exactly 10 digits"
          />
          <Select
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="city"
            required
          >
            <option value="" disabled>Select City</option>
            {districts.map((district) => (
              <option key={district} value={district}>
                {district}
              </option>
            ))}
          </Select  >
          <Button type="submit">Sign Up</Button>
          <Button type="button" onClick={handleGoogleSignIn}>Sign In with Google</Button>
        </Form>
      </SignupBox>
    </SignupContainer>
  );
};
export default Signup;