import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useParams, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios'; // For fetching data from the server
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Styled component for Banner
const BannerContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%; /* Make the container take full width */
  .slick-prev, .slick-next {
    z-index: 1;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #F06223;
  }
  .slick-prev {
    left: 10px; // Position arrow inside the left side of the banner
  }
  .slick-next {
    right: 10px; // Position arrow inside the right side of the banner
  }
  .slick-prev:before, .slick-next:before {
    font-size: 40px;
    color: #F06223;
  }
  .slick-dots {
    bottom: 10px; // Adjust position as needed
  }
  .slick-dots li button:before {
    font-size: 12px;
    color: #ddd; // Inactive dot color
  }
  .slick-dots li.slick-active button:before {
    color: #F06223; // Active dot color
  }
`;
// Styled component for Banner Image
const BannerImage = styled.img`
  width: 100%;
  height: auto; /* Adjust the height automatically to maintain aspect ratio */
  object-fit: contain; /* Ensures the image is fully visible without being cut */
  display: block;
`;
const BannerText = styled.div`
  position: absolute;
  top: 3%; /* Move the text to the top of the banner */
  left: 50%; /* Align horizontally */
  transform: translateX(-50%); /* Center the text horizontally */
  color: white; /* Text color */
  background-color: black; /* Semi-transparent background */
  padding: 10px 20px; /* Padding around the text */
  border-radius: 5px; /* Optional rounding for the background box */
  font-size: 24px; /* Default font size for desktop */
  font-weight: bold;
  text-align: center; /* Center text */
  z-index: 2; /* Ensure text stays on top of the banner image */
  /* Media query for smaller screens (mobile) */
  @media (max-width: 768px) {
    font-size: 18px; /* Reduce font size on tablets and small screens */
    padding: 8px 16px; /* Adjust padding */
  }
  @media (max-width: 480px) {
    font-size: 16px; /* Further reduce font size on mobile screens */
    padding: 6px 12px; /* Further adjust padding for mobile */
  }
`;
const Banner = () => {
  const [bannerImages, setBannerImages] = useState([]);
  const { types } = useParams();
  // Fetch banner images from the server
  useEffect(() => {
    const fetchBannerImages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/bannerImage`);
        setBannerImages(response.data); // Store banner images in state
      } catch (error) {
        console.error('Error fetching banner images:', error);
      }
    };
    fetchBannerImages();
  }, []);
  // Slider settings
  const settings = {
    dots: true, // Enables the dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
   
  };
  return (
    <BannerContainer>
      <Slider {...settings}>
        {bannerImages.map((banner, index) =>
          banner.imageUrl.map((imageUrl, imgIndex) => (
            <div key={`${index}-${imgIndex}`}>
              <BannerImage src={imageUrl} alt={banner.alt || 'Banner Image'} />
            </div>
          ))
        )}
      </Slider>
      {/* Text displayed on top of all banner images */}
      <BannerText>
        Properties for {types === "Sell" ? "Buy" : types}
      </BannerText>
    </BannerContainer>
  );
};
export default Banner;