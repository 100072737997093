import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import useMediaQuery from "../useMediaQuery"; // Importing the custom hook
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";

// Define a simple loading spinner animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled component for the spinner
const Spinner = styled.div`
  border: 5px solid ${({ theme }) => theme.colors.light}; 
  border-top: 5px solid ${({ theme }) => theme.colors.medium}; 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
  margin: 20px auto;
`;

const Container = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 0 10px;
`;

const FeaturedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) => (props.isMobile || props.isTablet ? "column" : "row")};
  gap: ${(props) => (props.isMobile ? "20px" : "0")};

  @media (min-width: 768px) and (max-width: 1024px) {
    gap: 30px; /* Increase gap for tablets */
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  width: ${(props) => (props.isMobile || props.isTablet ? "100%" : "50%")};
`;

const StyledImage = styled.img`
  width: ${(props) => (props.isMobile || props.isTablet ? "90%" : "500px")};
  height: auto;
  border-radius: 20px;
`;

const TextContainer = styled.div`
  flex: 1;
  text-align: center;
  margin-top: ${(props) => (props.isMobile || props.isTablet ? "20px" : "0")};
  width: ${(props) => (props.isMobile || props.isTablet ? "100%" : "50%")};
  
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0 20px; /* Add some padding for better text alignment on tablets */
  }
`;

const Arrow = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: ${(props) => (props.isMobile ? "1.5rem" : "2rem")};
  margin: 0 10px;
  display: ${(props) => (props.isMobile ? "none" : "block")};
`;

const Button = styled.button`
  padding: ${(props) => (props.isMobile ? "8px 15px" : "10px 20px")};
  margin-top: 20px;
  background-color: ${(props) => props.theme.colors.medium};
  color: ${(props) => props.theme.colors.light};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: ${(props) => (props.isMobile ? "14px" : "16px")};
  
  &:hover {
    background-color: ${(props) => props.theme.colors.dark};
  }
`;

const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

const Thumbnail = styled.img`
  width: ${(props) => (props.isMobile || props.isTablet ? "60px" : "100px")};
  height: ${(props) => (props.isMobile || props.isTablet ? "60px" : "100px")};
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
  border: ${(props) => (props.isSelected ? `2px solid ${props.theme.colors.medium}` : "none")};
  opacity: ${(props) => (props.isSelected ? 1 : 0.5)};
`;

// Adding tablet view detection to the component
const FeaturedCollections = () => {
  const [properties, setProperties] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();
  const { types } = useParams();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1024px)"); // Detect tablet screens

  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true); // Start loading
      const response = await fetch(`${process.env.REACT_APP_API_URL}/collectionBuy`);
      const data = await response.json();
      setProperties(data);
      setLoading(false); // End loading once data is fetched
    };
    fetchProperties();
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % properties.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + properties.length) % properties.length);
  };

  const handleViewMore = () => {
    const propertyName = properties[currentIndex].title;
    navigate(`/propertylisting?types=${types}&propertyName=${propertyName}`);
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <Container>
      <h2 style={{ fontSize: isMobile || isTablet ? "2em" : "2.3em" }}>Featured Collections</h2>
      {loading ? (
        <Spinner /> // Show spinner while loading
      ) : properties.length > 0 ? (
        <>
          <FeaturedContainer isMobile={isMobile} isTablet={isTablet}>
            <ImageContainer isMobile={isMobile} isTablet={isTablet}>
              <StyledImage
                src={properties[currentIndex].image}
                alt={properties[currentIndex].title}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            </ImageContainer>
            <TextContainer isMobile={isMobile} isTablet={isTablet}>
              <h3 style={{ fontSize: isMobile || isTablet ? "2em" : "2.3em" }}>
                {properties[currentIndex].title}
              </h3>
              <p style={{ fontSize: isMobile || isTablet ? "1.2em" : "1.3em" }}>
                {properties[currentIndex].description}
              </p>
              <ThumbnailContainer>
                <Arrow isMobile={isMobile} isTablet={isTablet} onClick={handlePrevious}>
                  <IoIosArrowDropleft />
                </Arrow>
                {properties.map((property, index) => (
                  <Thumbnail
                    key={property._id}
                    src={property.image}
                    alt={property.title}
                    onClick={() => handleThumbnailClick(index)}
                    isSelected={index === currentIndex}
                    isMobile={isMobile}
                    isTablet={isTablet}
                  />
                ))}
                <Arrow isMobile={isMobile} isTablet={isTablet} onClick={handleNext}>
                  <IoIosArrowDropright />
                </Arrow>
              </ThumbnailContainer>
              <Button onClick={handleViewMore} isMobile={isMobile} isTablet={isTablet}>
                View More
              </Button>
            </TextContainer>
          </FeaturedContainer>
        </>
      ) : (
        <p>No properties found.</p>
      )}
    </Container>
  );
};

export default FeaturedCollections;
