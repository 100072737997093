import React from 'react';
import styled from 'styled-components';
import '@fortawesome/fontawesome-free/css/all.min.css';
const WhyChooseUsContainer = styled.section`
  text-align: center;
  // background-color: ${({ theme }) => theme.colors.light};
  padding: 40px 20px;
`;
const Heading = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  color: #000;
`;
const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: nowrap; /* Prevent wrapping, keeping all cards in one row */
  @media (max-width: 1024px) {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
  }
`;
const Card = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 23%; /* Fixed width to ensure all cards fit in one row on large screens */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  @media (max-width: 1024px) {
    width: 100%; /* 2 cards per row on medium screens */
    margin-bottom: 20px; /* Add some space at the bottom for wrapping cards */
  }
  @media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
  }
`;
const Icon = styled.div`
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #000;
`;
const CardTitle = styled.h3`
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #000;
`;
const CardDescription = styled.p`
  font-size: 0.95em;
  color: #333;
`;
const WhyChooseUs = () => {
  return (
    <WhyChooseUsContainer>
      <Heading>Why Choose Us?</Heading>
      <CardsContainer>
        <Card>
          <Icon>
            <i className="fas fa-chart-bar"></i>
          </Icon>
          <CardTitle>Market Expertise</CardTitle>
          <CardDescription>
            Benefit from our in-depth market knowledge and experience guiding every decision.
          </CardDescription>
        </Card>
        <Card>
          <Icon>
            <i className="fas fa-handshake"></i>
          </Icon>
          <CardTitle>Personalized Services</CardTitle>
          <CardDescription>
            We offer tailored real estate solutions, ensuring a smooth and customized experience.
          </CardDescription>
        </Card>
        <Card>
          <Icon>
            <i className="fas fa-network-wired"></i>
          </Icon>
          <CardTitle>Reliable Network</CardTitle>
          <CardDescription>
            Access our trusted connections to industry experts for a seamless property journey.
          </CardDescription>
        </Card>
        <Card>
          <Icon>
            <i className="fas fa-award"></i>
          </Icon>
          <CardTitle>Proven Success</CardTitle>
          <CardDescription>
            Our proven track record showcases our commitment to delivering successful outcomes.
          </CardDescription>
        </Card>
      </CardsContainer>
    </WhyChooseUsContainer>
  );
};
export default WhyChooseUs;