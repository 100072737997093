import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaTrashAlt } from 'react-icons/fa';
import Loader from '../Loader';

// Styled Components
const Container = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.light};
  color: ${({ theme }) => theme.colors.dark};
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; /* Center content */

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const CityDropdown = styled.select`
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 20px;
  font-family: 'lora';
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    border-color: #888;
  }

  &:focus {
    outline: none;
    border-color: #0056b3; /* Focus border color */
  }

  @media (max-width: 768px) {
    font-size: 16px;
    font-family: 'lora';
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto; /* Allows horizontal scrolling on small screens */
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.light};
  text-align: left;

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableData = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }
`;

const DeleteIcon = styled(FaTrashAlt)`
  color: red;
  cursor: pointer;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
`;

const ModalButton = styled.button`
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${({ cancel }) => (cancel ? '#ccc' : '#ff5c5c')};
  color: white;

  &:hover {
    background-color: ${({ cancel }) => (cancel ? '#aaa' : '#ff1c1c')};
  }
`;

// Customer Details Component
const CustomerDetails = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCity, setSelectedCity] = useState('');
  const [cities, setCities] = useState([]);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [customerToDelete, setCustomerToDelete] = useState(null); // To track which customer is selected for deletion

  // Fetch cities
  const fetchCities = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/cities`);
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities', error);
    }
  };

  // Fetch customers
  const fetchCustomers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/customerDetailAdmin`);
      setCustomers(response.data);
      setFilteredCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customer details', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCities();
    fetchCustomers();
  }, []);

  // Handle city selection
  const handleCityChange = (city) => {
    setSelectedCity(city);
    setLoading(true);

    if (city === '') {
      setFilteredCustomers(customers);
    } else {
      const filtered = customers.filter((customer) => customer.city === city);
      setFilteredCustomers(filtered);
    }

    setLoading(false);
  };

  // Show modal for confirmation
  const confirmDeleteCustomer = (customer) => {
    setCustomerToDelete(customer);
    setShowModal(true);
  };

  // Delete customer function
  const deleteCustomer = async () => {
    if (!customerToDelete) return;

    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/deleteUser/${customerToDelete._id}`);
      if (response.status === 200) {
        setFilteredCustomers((prevCustomers) => prevCustomers.filter((user) => user._id !== customerToDelete._id));
        setShowModal(false); // Close the modal after deletion
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  if (loading) return <Loader/>;

  return (
    <Container>
      <Title>Customer Details</Title>
      <CityDropdown value={selectedCity} onChange={(e) => handleCityChange(e.target.value)}>
        <option value="">All Cities</option>
        {Array.isArray(cities) && cities.length > 0 ? (
          cities.map((city) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))
        ) : (
          <option disabled>No cities available</option>
        )}
      </CityDropdown>

      <TableContainer>
        <Table>
          <thead>
            <tr>
              <TableHeader>First Name</TableHeader>
              <TableHeader>Last Name</TableHeader>
              <TableHeader>Email</TableHeader>
              <TableHeader>Phone Number</TableHeader>
              <TableHeader>City</TableHeader>
              {/* <TableHeader>Actions</TableHeader> */}
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.length > 0 ? (
              filteredCustomers.map((detail) => (
                <TableRow key={detail._id}>
                  <TableData>{detail.firstName}</TableData>
                  <TableData>{detail.lastName}</TableData>
                  <TableData>{detail.email}</TableData>
                  <TableData>{detail.phoneNumber}</TableData>
                  <TableData>{detail.city}</TableData>
                  {/* <TableData>
                    <DeleteIcon onClick={() => confirmDeleteCustomer(detail)} />
                  </TableData> */}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableData colSpan="6">No customers found for the selected city.</TableData>
              </TableRow>
            )}
          </tbody>
        </Table>
      </TableContainer>

      {/* Modal for Delete Confirmation */}
      {showModal && (
        <ModalBackdrop>
          <ModalContent>
            <p>Are you sure you want to delete this customer?</p>
            <ModalButton onClick={deleteCustomer}>Yes</ModalButton>
            <ModalButton cancel onClick={() => setShowModal(false)}>No</ModalButton>
          </ModalContent>
        </ModalBackdrop>
      )}
    </Container>
  );
};

export default CustomerDetails;
